import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Page from '@lib/components/v2/Page';
import { toDocumentType } from '@lib/constants/documents';
import { Form } from 'calidation';
import { getAge } from '@lib/Utils';
import { isAgeEligible, selectEligibleAge } from '@js/domain/ageEligibility';
import { AgeReview } from '@lib/components/v2/AgeReview';
import parse from 'html-react-parser';
import { localizedString } from '@languages';
import Message from '@lib/components/v2/Message';
import {
  DATA_CHECK_ATTEMPT_COUNT,
  ENABLE_CONFIRM_AGE,
  FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_EXIT_BUTTON_ENABLED
} from '@spotMobileConfig';
import { Error500 } from '../../errors';
import APIs from '../../../services/APIs';
import { DataCheckContent, ChooseAnotherID } from '../../components/Contents';

export const Datacheck = ({
  documentId,
  isFlowV2DiffId,
  onNextStep,
  on500Error,
  onChooseDiffId
}) => {
  const [currentDoc, setCurrentDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});
  const [chooseDiffId, setChooseDiffId] = useState(false);
  const [showFailedScreen, setShowFailedScreen] = useState(false);
  const [showAfterExitScreen, setShowAfterExitScreen] = useState(false);
  const [hasFormErrors, setHasFormErrors] = useState(false);
  const eligibleAge = useSelector(selectEligibleAge);

  useEffect(() => {
    triggerDataCheck();
  }, []);

  const { component: Error, props: errorProps } = error || {};

  const buttons = [];

  if (!loading) {
    buttons.push({
      label: 'Confirm Data',
      type: 'submit',
      disabled: hasFormErrors
    });
  }

  const selectDiffIdTitle = localizedString('dataCheck.FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_TITLE');

  if (showFailedScreen && !loading) {
    const issueButtons = [
      {
        label: chooseDiffId
          ? localizedString('dataCheck.FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_BUTTON_TEXT')
          : localizedString('dataCheck.FLOW_V2_DATA_CHECK_REVIEW_DATA_BUTTON_TEXT'),
        type: 'submit',
        onClick: () => setShowFailedScreen(false),
        dataTestId: 'datacheck-confirm-prompt-cta'
      }
    ];

    if (chooseDiffId && FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_EXIT_BUTTON_ENABLED) {
      issueButtons.push({
        label: localizedString('flowV2.dataCheck.exitButton.label'),
        type: 'submit',
        onClick: () => handleClickExit(),
        rounded: true,
        dataTestId: 'datacheck-exit-prompt-cta'
      });
    }

    return (
      <div>
        <Message
          buttons={issueButtons}
          title={
            chooseDiffId
              ? selectDiffIdTitle
              : localizedString('dataCheck.FLOW_V2_DATA_CHECK_NO_MATCH_TITLE')
          }
          issue
        >
          {chooseDiffId
            ? parse(localizedString('dataCheck.FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_MESSAGE'))
            : parse(localizedString('dataCheck.FLOW_V2_DATA_CHECK_NO_MATCH_INFO_MESSAGE'))}
        </Message>
      </div>
    );
  }

  if (showAfterExitScreen) {
    return (
      <Message title={selectDiffIdTitle} issue>
        {parse(localizedString('dataCheck.FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_EXIT_MESSAGE'))}
      </Message>
    );
  }

  if (chooseDiffId && !loading) {
    return <ChooseAnotherID currentDoc={currentDoc} onNextStep={onChooseDiffId} />;
  }

  return (
    <Form onUpdate={handleFormUpdate} onSubmit={triggerDataCheck}>
      {Error && <Error {...errorProps} />}
      <Page title="Data Check" buttons={buttons}>
        <DataCheckContent onChange={handleChange} isLoading={loading} data={data} />
      </Page>
    </Form>
  );

  async function triggerDataCheck() {
    if (!isEmpty(data)) {
      const age = getAge(data.dateOfBirth, true);
      if (ENABLE_CONFIRM_AGE && !isAgeEligible(age, eligibleAge)) {
        const errorType = {
          component: AgeReview,
          props: {
            eligibleAge,
            onTryAgain: () => setError(null)
          }
        };
        setError(errorType);
        return;
      }
    }

    setLoading(true);
    setShowFailedScreen(false);

    APIs.callDataCheck({ data, documentId })
      .then(async ({ status, dataCheckInputs = null }) => {
        if (status !== 'success') {
          if (isFlowV2DiffId) {
            onNextStep();
            return;
          }
          const temp = { ...dataCheckInputs };

          setData({ ...temp });
          setLoading(false);
          setShowFailedScreen(true);

          let count = 1;
          if (!sessionStorage.getItem('flow_v2_datacheck_count')) {
            sessionStorage.setItem('flow_v2_datacheck_count', count);
          } else {
            let count2 = sessionStorage.getItem('flow_v2_datacheck_count');
            count2 = parseInt(count2, 10);
            sessionStorage.setItem('flow_v2_datacheck_count', count2 + 1);
            count = count2 + 1;
          }

          if (count >= DATA_CHECK_ATTEMPT_COUNT) {
            setChooseDiffId(true);
            setCurrentDoc({
              type: toDocumentType(dataCheckInputs.cardType) || dataCheckInputs.cardType
            });
          }
          return;
        }
        onNextStep();
      })
      .catch(({ message }) => {
        console.error(message);
        const errorType = {
          component: Error500,
          props: {
            onTryAgain: () => {
              on500Error();
            }
          }
        };
        setError(errorType);
        setLoading(false);
      });
  }

  function handleChange(field, value) {
    setData({
      ...data,
      [field]: value
    });
  }

  function handleFormUpdate({ fields, isValid }) {
    if (Object.keys(fields).length > 0) {
      setHasFormErrors(!isValid);
    }
  }

  function handleClickExit() {
    setShowAfterExitScreen(true);
    setShowFailedScreen(false);
  }
};

Datacheck.propTypes = {
  documentId: PropTypes.string,
  isFlowV2DiffId: PropTypes.bool,
  onNextStep: PropTypes.func,
  on500Error: PropTypes.func,
  onChooseDiffId: PropTypes.func
};
