import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Button } from '@lib/components/v2/Form';
import classes from './CustomMultipleSelectWidget.style.module.scss';

export const CustomMultipleSelectWidget = (props) => {
  const { options, onChange, placeholder, value = [], schema, id } = props;
  const { quickAdd = [] } = schema;

  return (
    <>
      <div className={classes.quickAddButtonGroup}>
        {quickAdd.map((quickAddItem) => {
          return (
            <Button
              key={`${id}_quick_add_${quickAddItem}`}
              onClick={() => {
                if (value.includes(quickAddItem)) {
                  return;
                }
                onChange([...value, quickAddItem]);
              }}
              label={`Add ${quickAddItem}`}
              dataTestId="cptrId-next"
              className={classes.quickAddButton}
            />
          );
        })}
      </div>
      <Select
        isMulti
        options={options.enumOptions || []}
        placeholder={placeholder}
        value={value.map((val) => {
          return { label: val, value: val };
        })}
        onChange={(val) => {
          onChange((val ?? []).map((row) => row.value));
        }}
      />
    </>
  );
};

CustomMultipleSelectWidget.propTypes = {
  id: PropTypes.string,
  options: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  schema: PropTypes.shape({
    quickAdd: PropTypes.array
  })
};
