import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Title } from '@lib/components/v2/Title';
import Page from '@lib/components/v2/Page';
import { Radio } from '@lib/components/v2/Form';
import classes from './MedicareNames.style.module.scss';

export const MedicareNames = ({ names = {}, onGoBack, onSelected }) => {
  const [selectedKey, setSelectedKey] = useState();

  const footerButtons = [
    {
      label: 'Back',
      variant: 'transparent',
      onClick: onGoBack
    },
    {
      label: 'Continue',
      type: 'submit',
      onClick: handleClickContinue
    }
  ];

  function handleClickContinue(e) {
    e?.preventDefault?.();

    if (selectedKey) {
      const data = {
        fullName: names[selectedKey],
        medicareRefNumber: selectedKey
      };
      onSelected(data);
    }
  }

  return (
    <Page buttons={footerButtons}>
      <div className={classes.wrapper}>
        <Title title="Which number reference are you?" />
        <div className={classes.option_list}>
          {Object.keys(names).map((key) => (
            <Radio
              key={key}
              id={`medicareName-${key}`}
              onChange={() => setSelectedKey(key)}
              checked={selectedKey === key}
              title={`${key} ${names[key]}`}
              className={classes.option_item}
            />
          ))}
        </div>
      </div>
    </Page>
  );
};

MedicareNames.propTypes = {
  names: PropTypes.object.isRequired,
  onSelected: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired
};
