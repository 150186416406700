import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import parse from 'html-react-parser';
import Animation, { useAnimationUrls } from '@components/Animation';
import { CountrySelect } from '@lib/components/v2/Form';
import { getCountryLabelFromIso2Code } from '@lib/countryUtils';
import { localizedString } from '@languages';
import { useAllowedDocumentsInIDVC } from '@js/hooks/useAllowedDocumentsInIDVC';
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_COUNTRY_LABEL,
  FLOW_V2_ACCEPTABLE_DOCS_IP_COUNTRY_SELECTED,
  FLOW_V2_ALLOW_MANUAL_ENTRY,
  FLOW_V2_ID_SELECTION_SHOW_ACCEPTABLE_DOCS,
  FLOW_V2_LOADING_DETAILS_PROGRESS_BAR
} from '@spotMobileConfig';
import Button from '@lib/components/v2/Form/Button';
import LoadingBar from '@lib/components/v2/LoadingBar';
import { Title } from '@lib/components/v2/Title';
import classes from './IdSelection.style.module.scss';
import iso3 from '../../../../data/iso3.json';

const IdSelection = ({
  engine4Config = {},
  ipCountryCode = '',
  acceptableDocs = false,
  onShowAcceptableDocsClick,
  isLoading = false,
  idType,
  progressBar,
  onShowCaptureDigitalIdScreen = () => {},
  onSelectManualEntry = () => {}
}) => {
  const { animationUrls } = useAnimationUrls();
  const { acceptedCountries } = engine4Config;

  const [country, setCountry] = useState(() => {
    if (FLOW_V2_ACCEPTABLE_DOCS_IP_COUNTRY_SELECTED && ipCountryCode) {
      return {
        value: ipCountryCode,
        label: getCountryLabelFromIso2Code(ipCountryCode)
      };
    }

    return {
      value: DEFAULT_COUNTRY_CODE,
      label: DEFAULT_COUNTRY_LABEL
    };
  });

  const iso3Country = iso3[country.value];
  const { cardTypeObject, isDigitalIdEnabled } = useAllowedDocumentsInIDVC(iso3Country);

  const handleCountryChange = (val) => {
    setCountry(val);
  };

  return (
    <div style={{ height: '100%' }}>
      {!acceptableDocs && !isLoading && (
        <div className={classes.wrapper}>
          <Title
            data-testid="cptrId-heading"
            title={localizedString('idSelection.FLOW_V2_ID_SELECTION_HEADING')}
          />
          <div className={classes.description} data-testid="cptrId-instruction">
            {parse(localizedString('idSelection.FLOW_V2_ID_SELECTION_DESCRIPTION'))}
          </div>
          <div className={classes['animation-container']} data-testid="cptrId-animation">
            <Animation
              animationUrl={idType !== 'PASSPORT' ? animationUrls.CARD : animationUrls.PASSPORT}
            />
          </div>

          <div className={classes.link_list}>
            {FLOW_V2_ID_SELECTION_SHOW_ACCEPTABLE_DOCS && (
              <Button
                onClick={onShowAcceptableDocsClick}
                className={classes.link}
                variant="transparent"
              >
                <span>{localizedString('acceptableDocs')}</span>
                <img alt="" src="images/icons/png/next-videoid.png" />
              </Button>
            )}
            {isDigitalIdEnabled && (
              <Button
                onClick={onShowCaptureDigitalIdScreen}
                className={classes.link}
                variant="transparent"
              >
                <span>{localizedString('idSelection.FLOW_V2_DIGITAL_ID_ACCEPTANCE_TITLE')}</span>
                <img alt="arrow" src="images/icons/png/next-videoid.png" />
              </Button>
            )}
            {FLOW_V2_ALLOW_MANUAL_ENTRY && (
              <Button onClick={onSelectManualEntry} className={classes.link} variant="transparent">
                <span>Enter details manually instead</span>
                <img alt="arrow" src="images/icons/png/next-videoid.png" />
              </Button>
            )}
          </div>
        </div>
      )}

      {acceptableDocs && (
        <div className={classes.wrapper}>
          <Title data-testid="accepted-heading" title={localizedString('acceptedIdDocs')} />
          <div aria-atomic="true" aria-live="assertive">
            <CountrySelect
              filter={acceptedCountries}
              value={country}
              onChange={handleCountryChange}
            />
            <div className={classes.accepted}>
              <ul>
                {Object.keys(cardTypeObject).map((card, index) => {
                  return (
                    <li key={card} data-testid={`accepted-doc${index}`}>
                      {cardTypeObject[card].label}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className={classes.wrapper}>
          <Title
            data-testid="id-heading"
            title={localizedString('idSelection.FLOW_V2_LOADING_DETAILS_HEADING')}
          />
          <div className={classnames(classes.description)} data-testid="id-instruction">
            {parse(localizedString('idSelection.FLOW_V2_LOADING_DETAILS_DESCRIPTION'))}
          </div>
          <div className={classes['animation-container']} data-testid="id-animation">
            <Animation animationUrl={animationUrls.REVIEW} />
          </div>
          {FLOW_V2_LOADING_DETAILS_PROGRESS_BAR && <LoadingBar width={progressBar} />}
        </div>
      )}
    </div>
  );
};

IdSelection.propTypes = {
  engine4Config: PropTypes.object,
  acceptableDocs: PropTypes.bool,
  onShowAcceptableDocsClick: PropTypes.func,
  isLoading: PropTypes.bool,
  idType: PropTypes.string,
  progressBar: PropTypes.number,
  onShowCaptureDigitalIdScreen: PropTypes.func,
  onSelectManualEntry: PropTypes.func,
  ipCountryCode: PropTypes.string
};
export default IdSelection;
