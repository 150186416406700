import React from 'react';
import PropTypes from 'prop-types';
import { Validation } from 'calidation';
import classNames from 'classnames';
import { Title } from '@lib/components/v2/Title';
import { camelCaseToInitialCapital } from '@lib/Utils';
import { DatePicker, Input } from '@lib/components/v2/Form';
import { isProofOfAgeDocument } from '@lib/constants/documents';
import { isEmptyExpiryDateAllowed } from '@lib/utils/checkExpiredDocument';
import { isLocalizedStringDefined, localizedString } from '@js/languages';

import { FLOW_V2_DATEPICKER_FORMAT } from '@spotMobileConfig';

import classes from './VerifyAdditionalDetails.style.module.scss';
import FormModel from './VerifyAdditionalDetails.form';

const VerifyAdditionalDetails = ({
  pageTitle = 'Are your ID details correct?',
  onChange,
  idTypeTitle,
  useIdNumber,
  data,
  isSubmiting,
  flowType
}) => {
  const idType = data.cardType || '';

  let NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING = '';

  if (isLocalizedStringDefined('app.VOI_FLOW_V2_VERIFY_DETAILS_DESC')) {
    NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING = localizedString(
      'app.VOI_FLOW_V2_VERIFY_DETAILS_DESC',
      idTypeTitle
    );
  }

  if (
    isLocalizedStringDefined('app.VOI_FLOW_V2_VERIFY_DETAILS_DESC_AU_DRIVER_LICENCE') &&
    localizedString('app.VOI_FLOW_V2_VERIFY_DETAILS_DESC_AU_DRIVER_LICENCE') &&
    data.country === 'AU' &&
    idType.match(/LICENCE/i)
  ) {
    NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING = localizedString(
      'app.VOI_FLOW_V2_VERIFY_DETAILS_DESC_AU_DRIVER_LICENCE'
    );
  }

  let dataValue;
  if (
    idType &&
    (idType.match(/PASSPORT/i) || idType.match(/IMMI/i) || isProofOfAgeDocument(idType))
  ) {
    dataValue = {
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      dateOfBirth: data.dateOfBirth,
      idNumber: data.idNumber,
      expiryDate: data.expiryDate
    };
  } else {
    dataValue = { ...data };
    const excluded = [
      'asf',
      'cardType',
      'addressApiCalls',
      'checkConfirm',
      'countryOfIssue',
      'countryCode',
      'engineCountryCode',
      'categories0',
      'expiryDate0',
      'issueDate0',
      'issuer',
      'evidenceNo',
      'documentId',
      'addressData',
      ...(idType?.match(/MEDICARE/i) ? ['dateOfBirth'] : ['expiryDate'])
    ];
    excluded.forEach((field) => {
      delete dataValue[field];
    });
  }

  const datePickerProps = { displayedDateFormat: FLOW_V2_DATEPICKER_FORMAT };
  if (idType?.match(/MEDICARE/i)) {
    datePickerProps.displayedDateFormat = 'MM-YYYY';
  }

  const fields = [];
  Object.keys(dataValue).forEach((field) => {
    let label = field;

    if (field.match(/date/i)) {
      if (field === 'expiryDate') {
        label = 'dateOfExpiry';
      }

      fields.push({
        id: field,
        label: isLocalizedStringDefined(label)
          ? localizedString(label)
          : camelCaseToInitialCapital(label),
        value: dataValue[field],
        date: true
      });
    } else {
      let type = 'text';
      if (field === 'medicareRefNumber') {
        type = 'number';
        label = 'medicareReference';
      } else if (field === 'cardNumber' && idType.match(/CITIZEN/i)) {
        label = 'certificateNumber';
      }

      if (field === 'lastName') {
        label = isLocalizedStringDefined('surname')
          ? localizedString('surname')
          : camelCaseToInitialCapital(label);
      } else {
        label = isLocalizedStringDefined(label)
          ? localizedString(label)
          : camelCaseToInitialCapital(label);
      }

      fields.push({
        id: field,
        label,
        value: dataValue[field],
        type
      });
    }
  });

  /**
   * Generate form fields.
   */
  const generateFields = fields.map((field, i) => {
    const { id, label, value, date } = field;
    const labelDataTestId = `details-field-${i}-lbl`;
    const dataTestId = `details-field-${i}`;

    const nId = useIdNumber && id === 'passportNumber' ? 'idNumber' : id;

    let validationRule = FormModel[nId] || { [nId]: {} };
    if (idType) {
      let required = [];
      if (idType.match(/MEDICARE/i)) {
        required = ['expiryDate', 'medicareRefNumber'];
      } else if (idType.match(/CITIZEN/i)) {
        required = ['cardNumber'];
      }
      if (required.includes(id)) {
        const fieldName = camelCaseToInitialCapital(label);
        validationRule = {
          [id]: {
            isRequired: `Please enter ${fieldName}`
          }
        };
      }
    }

    if (nId === 'expiryDate' && isEmptyExpiryDateAllowed({ flowType, cardType: data.cardType })) {
      const { isRequired, ...expiryDateWithoutRequired } = validationRule.expiryDate;
      validationRule = { expiryDate: expiryDateWithoutRequired };
    }

    // if its date
    if (date) {
      const restProps = {
        id,
        label,
        value
      };
      return (
        <div key={id} className={classNames('', classes.userDetailRow)}>
          <label data-testid={labelDataTestId} id={`label-${id}`} aria-hidden="true" htmlFor={id}>
            {field.label}
          </label>
          <Validation config={validationRule} initialValues={{ [id]: value }}>
            {({ errors: formErrors, setField }) => {
              return (
                <DatePicker
                  key={id}
                  {...datePickerProps}
                  className={classNames(classes.input)}
                  hasError={formErrors[id]}
                  onChange={(value) => {
                    onChange(id, value);
                    setField({ [id]: value });
                  }}
                  {...restProps}
                  dataTestId={`${dataTestId}-datePicker`}
                  disabled={isSubmiting}
                />
              );
            }}
          </Validation>
        </div>
      );
    }

    return (
      <div key={id} className={classNames('', classes.userDetailRow)}>
        <label data-testid={labelDataTestId} id={`label-${id}`} aria-hidden="true" htmlFor={id}>
          {field.label}
        </label>
        <Validation config={validationRule} initialValues={{ [id]: value }}>
          {({ dirty, errors: formErrors, setField }) => {
            return (
              <Input
                key={id}
                placeholder={field.label}
                paddingLeft30
                className={classNames(classes.input)}
                hasError={dirty[id] ? formErrors[id] : null}
                onChange={(value) => {
                  onChange(id, value);
                  setField({ [id]: value });
                }}
                {...field}
                dataTestId={`${dataTestId}-txt`}
                disabled={isSubmiting}
              />
            );
          }}
        </Validation>
      </div>
    );
  });

  return (
    <div className={classes.wrapper}>
      <Title data-testid="details-heading" title={pageTitle} />
      <div className={classes.description}>{NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING}</div>
      <div className={classes.container}>{generateFields}</div>
    </div>
  );
};

VerifyAdditionalDetails.propTypes = {
  onChange: PropTypes.func,
  errors: PropTypes.object,
  data: PropTypes.object,
  pageTitle: PropTypes.string,
  idTypeTitle: PropTypes.string,
  flowType: PropTypes.string,
  useIdNumber: PropTypes.bool,
  isSubmiting: PropTypes.bool
};

VerifyAdditionalDetails.defaultProps = {
  errors: {},
  data: {},
  onChange: () => {},
  isSubmiting: false
};

export default VerifyAdditionalDetails;
