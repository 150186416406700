import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import APIs from '@services/APIs';
import QuestionnaireForm from '@lib/components/v2/QuestionnaireForm';

class Questionnaire extends Component {
  constructor(props) {
    super(props);

    this.handleExit = this.handleExit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleExit() {
    const { onGoBack } = this.props;
    onGoBack();
  }

  handleSubmit(formData) {
    const { onComplete } = this.props;
    APIs.storeQuestionnaireAnswers(formData);
    onComplete();
  }

  getQuestionnaire() {
    const { questionnaire } = this.props;

    return questionnaire;
  }

  render() {
    const questionnaire = this.getQuestionnaire();
    const {
      schemas,
      uiSchemas,
      schemaValidationErrors = {},
      theme = '',
      enableQuestionnaireReview = false
    } = questionnaire;
    const { enableExitButton } = this.props;

    return (
      <div>
        <QuestionnaireForm
          enableExitButton={enableExitButton}
          schemaValidationErrors={schemaValidationErrors}
          theme={theme}
          schemas={schemas}
          uiSchemas={uiSchemas}
          enableQuestionnaireReview={enableQuestionnaireReview}
          onExit={() => this.handleExit()}
          onSubmit={(formData) => this.handleSubmit(formData)}
        />
      </div>
    );
  }
}

Questionnaire.propTypes = {
  onGoBack: PropTypes.func,
  onComplete: PropTypes.func,
  questionnaire: PropTypes.object,
  enableExitButton: PropTypes.bool
};

export default connect(null, null)(Questionnaire);
