import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import { getCustomStyles } from './Select.styles';
import classes from './Select.style.module.scss';

export const Select = ({
  id,
  className,
  disabled,
  readOnly,
  hasError,
  options = [],
  isLoading = false,
  borderBottomOnly = false,
  paddingLeft30,
  ...restProps
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const { value, placeholder } = restProps;

  const { customStyles, focusedLabelStyle, selectedLabelStyle } = getCustomStyles({
    borderBottomOnly,
    classes,
    paddingLeft30,
    isOpened
  });

  return (
    <div className={classNames(classes.Select)}>
      {value && !borderBottomOnly && (
        <div style={{ ...focusedLabelStyle, ...selectedLabelStyle }}>{placeholder}</div>
      )}
      <ReactSelect
        id={id}
        className={classNames(className, 'minimal')}
        options={options}
        isSearchable={false}
        isDisabled={disabled}
        isLoading={isLoading}
        readOnly={readOnly}
        styles={customStyles}
        onMenuOpen={() => {
          setIsOpened(true);
          focusedLabelStyle.color = classes.secondary;
        }}
        onMenuClose={() => {
          setIsOpened(false);
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors
          }
        })}
        {...restProps}
      />
      {hasError && <div className={classes.hasError}>{hasError}</div>}
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  hasError: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  options: PropTypes.array,
  paddingLeft30: PropTypes.bool,
  disabled: PropTypes.bool,
  borderBottomOnly: PropTypes.bool
};
