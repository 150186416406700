import axios from 'axios';

const BASE_URL = '/api/3.5';

export const checkDataOnlyDetails = (details) => {
  const source = axios.CancelToken.source();

  return axios
    .post(`${BASE_URL}/dataCheckForPortal`, details, { cancelToken: source.token })
    .then(({ data }) => {
      if (data.status === 'error') {
        throw new Error(data.message || 'Unexpected Error');
      }
      return data;
    });
};
