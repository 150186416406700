import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import Page from '@lib/components/v2/Page';
import { localizedString } from '@languages';
import Animation, { useAnimationUrls } from '@components/Animation';
import { FLOW_V2_LOADING_DETAILS_PROGRESS_BAR } from '@spotMobileConfig';
import Message from '@lib/components/v2/Message';
import APIs from '@services/APIs';
import LoadingBar from '@lib/components/v2/LoadingBar';
import { Title } from '@lib/components/v2/Title';
import classes from './ProofOfAddress.module.scss';

const STATUS = {
  IDLE: 'IDLE',
  UPLOADING: 'UPLOADING',
  ERROR_UPLOADING: 'ERROR_UPLOADING',
  ERROR_SELECTING_FILE: 'ERROR_SELECTING_FILE',
  GETTING_RESULT: 'GETTING_RESULT',
  ERROR_GETTING_RESULT: 'ERROR_GETTING_RESULT'
};

const ERROR_LOCALIZED_TITLE_KEYS = {
  ADDRESS_COULD_NOT_BE_FOUND: 'proofOfAddressError.ADDRESS_COULD_NOT_BE_FOUND_TITLE',
  ADDRESS_DOES_NOT_MATCH: 'proofOfAddressError.ADDRESS_DOES_NOT_MATCH_TITLE',
  DOC_COULD_NOT_BE_OPENED: 'proofOfAddressError.DOC_COULD_NOT_BE_OPENED_TITLE',
  NAME_DOES_NOT_MATCH: 'proofOfAddressError.NAME_DOES_NOT_MATCH_TITLE',
  THIS_IS_NOT_A_RECENT_DOC: 'proofOfAddressError.THIS_IS_NOT_A_RECENT_DOC_TITLE'
};

const ERROR_LOCALIZED_DESCRIPTION_KEYS = {
  ADDRESS_COULD_NOT_BE_FOUND: 'proofOfAddressError.ADDRESS_COULD_NOT_BE_FOUND_DESCRIPTION',
  ADDRESS_DOES_NOT_MATCH: 'proofOfAddressError.ADDRESS_DOES_NOT_MATCH_DESCRIPTION',
  DOC_COULD_NOT_BE_OPENED: 'proofOfAddressError.DOC_COULD_NOT_BE_OPENED_DESCRIPTION',
  NAME_DOES_NOT_MATCH: 'proofOfAddressError.NAME_DOES_NOT_MATCH_DESCRIPTION',
  THIS_IS_NOT_A_RECENT_DOC: 'proofOfAddressError.THIS_IS_NOT_A_RECENT_DOC_DESCRIPTION'
};

const POA_CAPTURE_MECHANISMS = {
  PDF: 'pdf',
  UPLOAD_IMAGE: 'upload_image',
  CAMERA: 'camera'
};

const DEFAULT_POA_CAPTURE_MECHANISM = [
  POA_CAPTURE_MECHANISMS.PDF,
  POA_CAPTURE_MECHANISMS.UPLOAD_IMAGE,
  POA_CAPTURE_MECHANISMS.CAMERA
];

const MIMETYPES_PER_CAPTURE_MECHANISM = {
  [POA_CAPTURE_MECHANISMS.CAMERA]: ['image/*'],
  [POA_CAPTURE_MECHANISMS.UPLOAD_IMAGE]: ['image/*'],
  [POA_CAPTURE_MECHANISMS.PDF]: [
    '.pdf',
    'application/pdf',
    'application/x-pdf',
    'application/x-bzpdf',
    'application-gzpdf'
  ]
};

export const ProofOfAddress = ({ flowType, onBack, onNextStep }) => {
  const { animationUrls } = useAnimationUrls();
  const inputFileRef = useRef();
  const [currentStatus, setCurrentStatus] = useState(STATUS.IDLE);
  const [currentErrorType, setCurrentErrorType] = useState();

  const poaCaptureMechanism = useSelector(
    ({ appConfig }) => appConfig?.poaCaptureMechanism || DEFAULT_POA_CAPTURE_MECHANISM
  );

  const isOnlyCameraAllowed = isEqual(poaCaptureMechanism, [POA_CAPTURE_MECHANISMS.CAMERA]);
  const isOnlyPdfAllowed = isEqual(poaCaptureMechanism, [POA_CAPTURE_MECHANISMS.PDF]);

  const acceptedMimeTypes = [
    ...new Set(poaCaptureMechanism.flatMap((key) => MIMETYPES_PER_CAPTURE_MECHANISM[key] || []))
  ].join(',');

  const footerButtons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: onBack,
      dataTestId: 'capture-poa-back'
    },
    {
      label: localizedString('proofOfAddressCapture.CAPTURE_BUTTON_LABEL'),
      type: 'submit',
      onClick: openDeviceFiles,
      dataTestId: 'capture-poa-submit'
    }
  ];

  const loadingFooterButtons = [
    {
      label: '   ',
      variant: 'transparent'
    },
    {
      label:
        currentStatus === STATUS.UPLOADING
          ? localizedString('uploading')
          : localizedString('loading'),
      variant: 'transparent',
      loading: true,
      dataTestId: 'capture-poa-uploading'
    }
  ];

  const errorFooterButtons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: () => setCurrentStatus(STATUS.IDLE),
      dataTestId: 'capture-poa-back'
    },
    {
      label: localizedString('recapture'),
      type: 'submit',
      onClick: openDeviceFiles,
      dataTestId: 'capture-poa-recapture'
    }
  ];

  const isLoading = [STATUS.UPLOADING, STATUS.GETTING_RESULT].includes(currentStatus);

  return (
    <>
      <input
        type="file"
        name="image"
        capture={isOnlyCameraAllowed}
        accept={acceptedMimeTypes}
        onChange={handleFileCapture}
        ref={inputFileRef}
        style={{ opacity: 0 }}
        data-testid="poa-upload-document-hidden-input"
        aria-hidden="true"
      />

      {[STATUS.IDLE, STATUS.UPLOADING, STATUS.GETTING_RESULT].includes(currentStatus) && (
        <Page buttons={isLoading ? loadingFooterButtons : footerButtons} forceFillViewPort>
          <div className={classes.wrapper}>
            <Title title={localizedString('proofOfAddressCapture.TITLE')} />
            <div className={classes.description}>
              {localizedString('proofOfAddressCapture.DESCRIPTION')}
            </div>
            <div className={classes['animation-container']} aria-hidden="true">
              <Animation animationUrl={animationUrls.PROOF_OF_ADDRESS} />
            </div>
            {isLoading && FLOW_V2_LOADING_DETAILS_PROGRESS_BAR && <LoadingBar width={100} />}
          </div>
        </Page>
      )}

      {[STATUS.ERROR_GETTING_RESULT, STATUS.ERROR_UPLOADING].includes(currentStatus) && (
        <Message
          buttons={errorFooterButtons}
          title={localizedString(
            ERROR_LOCALIZED_TITLE_KEYS[currentErrorType] ||
              ERROR_LOCALIZED_TITLE_KEYS.DOC_COULD_NOT_BE_OPENED
          )}
          issue
        >
          {localizedString(
            ERROR_LOCALIZED_DESCRIPTION_KEYS[currentErrorType] ||
              ERROR_LOCALIZED_DESCRIPTION_KEYS.DOC_COULD_NOT_BE_OPENED
          )}
        </Message>
      )}

      {STATUS.ERROR_SELECTING_FILE === currentStatus && (
        <Message
          buttons={errorFooterButtons}
          title={localizedString('proofOfAddressError.THIS_IS_NOT_A_VALID_PDF_FILE_TITLE')}
          issue
        >
          {localizedString('proofOfAddressError.THIS_IS_NOT_A_VALID_PDF_FILE_DESCRIPTION')}
        </Message>
      )}
    </>
  );

  function openDeviceFiles() {
    inputFileRef.current.click();
  }

  function handleFileCapture(e) {
    if (!e.target.files[0]) {
      return;
    }

    const imagefile = e.target.files[0];

    if (isOnlyPdfAllowed && !isFileLikelyPdf(imagefile)) {
      setCurrentStatus(STATUS.ERROR_SELECTING_FILE);
      return;
    }

    e.target.value = '';

    uploadImage(imagefile);
  }

  function isFileLikelyPdf(file) {
    const ext = file?.name.split('.').reverse()[0].toLowerCase();
    return (
      ext === 'pdf' ||
      MIMETYPES_PER_CAPTURE_MECHANISM[POA_CAPTURE_MECHANISMS.PDF].includes(file?.type)
    );
  }

  async function uploadImage(imagefile) {
    setCurrentStatus(STATUS.UPLOADING);

    const params = { frontFile: imagefile, flowType };
    let token;

    try {
      const response = await APIs.uploadImage(params, {});
      token = response.token;
      if (response.status === 'error') {
        throw response.msg;
      }
      if (!token) {
        throw new Error('No token returned');
      }
    } catch (error) {
      console.error(error);
      setCurrentStatus(STATUS.ERROR_UPLOADING);
      return;
    }

    setCurrentStatus(STATUS.GETTING_RESULT);

    let lastErrorType;
    try {
      const { status, errorType } = await APIs.extractIdentifyInfo(token, false, {
        isEngineV4: true,
        idType: 'Proof_of_Address',
        flowType
      });

      if (status === 'error') {
        lastErrorType = errorType;
        throw errorType;
      }

      onNextStep();
    } catch (error) {
      console.error(error);
      setCurrentErrorType(lastErrorType);
      setCurrentStatus(STATUS.ERROR_GETTING_RESULT);
    }
  }
};

ProofOfAddress.propTypes = {
  onBack: PropTypes.func,
  onNextStep: PropTypes.func,
  flowType: PropTypes.string
};
