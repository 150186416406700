import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const FlowTypeContext = React.createContext({});

export const withFlowTypeProvider = (OriginalComponent) => {
  function WithFlowTypeProvider(props) {
    const { flowType } = props;

    return (
      <FlowTypeContextProvider flowType={flowType}>
        <OriginalComponent {...props} />
      </FlowTypeContextProvider>
    );
  }

  WithFlowTypeProvider.propTypes = {
    flowType: PropTypes.string.isRequired
  };

  return WithFlowTypeProvider;
};

export const FlowTypeContextProvider = ({ children, flowType }) => {
  const value = useMemo(() => {
    return { flowType };
  }, [flowType]);
  return <FlowTypeContext.Provider value={value}>{children}</FlowTypeContext.Provider>;
};

FlowTypeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  flowType: PropTypes.string.isRequired
};

export const useFlowTypeContext = () => {
  const { flowType } = useContext(FlowTypeContext);

  if (flowType === undefined) {
    throw new Error(
      'flowType is undefined. Make sure useFlowTypeContext is used within a FlowTypeContext or set the value of flowType properly.'
    );
  }

  return { flowType };
};
