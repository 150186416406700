import PropTypes from 'prop-types';
import React, { useState } from 'react';
import APIs from '@services/APIs';
import { useSelector } from 'react-redux';
import { CANCEL_TXN_FOR_NOT_ELIGIBLE_AGE } from '@spotMobileConfig';
import { ConfirmAgePrompt } from './ConfirmAgePrompt';
import { UnderEligibleAgeMessage } from './UnderEligibleAgeMessage';

export const AgeReview = ({ eligibleAge, onTryAgain }) => {
  const [isNotEligibleAgeConfirmed, setIsNotEligibleAgeConfirmed] = useState(false);
  const cancelRedirectUrl = useSelector(({ appConfig }) => appConfig?.cancelRedirectUrl);

  const handleOnConfirmNotEligibleAge = () => {
    if (CANCEL_TXN_FOR_NOT_ELIGIBLE_AGE) {
      if (cancelRedirectUrl) {
        APIs.cancel();
      } else {
        APIs.cancelTransactionInBackground();
      }
    }
    setIsNotEligibleAgeConfirmed(true);
  };

  return (
    <>
      {!isNotEligibleAgeConfirmed && (
        <ConfirmAgePrompt
          eligibleAge={eligibleAge}
          onConfirm={handleOnConfirmNotEligibleAge}
          onReject={onTryAgain}
        />
      )}

      {isNotEligibleAgeConfirmed && <UnderEligibleAgeMessage eligibleAge={eligibleAge} />}
    </>
  );
};

AgeReview.propTypes = {
  eligibleAge: PropTypes.number,
  onTryAgain: PropTypes.func
};
