import classNames from 'classnames';
import { Validation } from 'calidation';
import { Checkbox, DatePicker, Input } from '@lib/components/v2/Form';
import { FLOW_V2_DATEPICKER_FORMAT } from '@spotMobileConfig';
import { localizedString } from '@languages';
import { AddressFinder } from '@lib/components/v2/AddressFinder';
import React from 'react';
import PropTypes from 'prop-types';

export const Fields = ({
  classes = {},
  formValidations,
  handleChange,
  isSubmiting,
  country,
  availableManualCountryCodesIso3,
  address,
  addressNative,
  addressData,
  getEmptyNameCheckboxConfig,
  fields
}) =>
  fields.map((field, i) => {
    const { id, label, value, date, addressFinder = false, disabled = false } = field;
    const dataTestIdPrefix = `details-field-${i}-`;
    const labelDataTestId = `${dataTestIdPrefix}lbl`;
    if (date) {
      return (
        <div key={id} className={classNames('', classes.userDetailRow)}>
          <label data-testid={labelDataTestId} id={`label-${id}`} htmlFor={id}>
            {field.label}
          </label>
          <Validation config={formValidations[id]} initialValues={{ [id]: value }}>
            {({ errors: formErrors, setField, fields }) => (
              <DatePicker
                key={id}
                className={classNames(classes.input)}
                hasError={formErrors[id]}
                onChange={(value) => {
                  handleChange({ id, value, limitEdit: true });
                  setField({ [id]: value });
                }}
                id={id}
                label={label}
                value={fields[id]}
                disabled={disabled || isSubmiting}
                dataTestId={`${dataTestIdPrefix}datePicker`}
                displayedDateFormat={FLOW_V2_DATEPICKER_FORMAT}
              />
            )}
          </Validation>
        </div>
      );
    }
    if (addressFinder) {
      const countryCodeIso2ToVerify = value?.manual
        ? value.selectedManualCountryCode || 'AU'
        : country;

      return (
        <div key={id} className={classNames('', classes.userDetailRow)}>
          <label
            data-testid={labelDataTestId}
            className={classes['address-label-span']}
            htmlFor="homeAddress"
          >
            {field.label || localizedString('residentalAddress')}
          </label>
          <Validation config={{}}>
            {({ setField }) => (
              <AddressFinder
                initialAddress={address}
                initialNativeAddress={addressNative}
                initialAddressData={addressData}
                countryCodeIso2ToVerify={countryCodeIso2ToVerify}
                availableManualCountryCodesIso3={availableManualCountryCodesIso3}
                onChange={(value) => {
                  handleChange({ id, value, limitEdit: false });
                  setField({ [id]: value });
                }}
                dataTestId={`${dataTestIdPrefix}search`}
                disabled={isSubmiting}
              />
            )}
          </Validation>
        </div>
      );
    }

    const emptyNameConfig = getEmptyNameCheckboxConfig?.(id, fields);
    return (
      <div key={id} className={classNames('', classes.userDetailRow)}>
        <label data-testid={labelDataTestId} id={`label-${id}`} htmlFor={id}>
          {field.label}
        </label>
        <Validation config={formValidations[id]} initialValues={{ [id]: value }}>
          {({ errors: formErrors, fields, setField }) => (
            <>
              <Input
                key={id}
                placeholder={field.label}
                paddingLeft30
                className={classNames(classes.input, classes.error)}
                hasError={formErrors[id]}
                onChange={(value) => {
                  handleChange({ id, value, limitEdit: true });
                  setField({ [id]: value });
                }}
                {...field}
                dataTestId={`${dataTestIdPrefix}txt`}
                disabled={isSubmiting || disabled}
              />
              {emptyNameConfig?.id && (
                <Checkbox
                  className={classNames(classes['confirm-name-empty'], classes.error)}
                  name={emptyNameConfig.id}
                  id={emptyNameConfig.id}
                  value={fields[emptyNameConfig.id]}
                  label={emptyNameConfig.label}
                />
              )}
            </>
          )}
        </Validation>
      </div>
    );
  });

Fields.propTypes = {
  fields: PropTypes.array.isRequired,
  formValidations: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isSubmiting: PropTypes.bool.isRequired,

  address: PropTypes.string,
  addressData: PropTypes.object,
  addressNative: PropTypes.string,
  availableManualCountryCodesIso3: PropTypes.array,
  country: PropTypes.string.isRequired,

  getEmptyNameCheckboxConfig: PropTypes.func
};
