/* eslint-disable camelcase */
import UAParser from 'ua-parser-js';
import {
  isMobile,
  isIOS,
  isSafari,
  isFirefox,
  isChrome,
  getUA,
  osVersion
} from 'react-device-detect';
import Moment from 'moment';
import compareVersions from 'compare-versions';
import { CHECK_DEVICE, HIDDEN_PRIVACY_IN } from '@spotMobileConfig';
import APIs from '../services/APIs';

const DetectRTC = require('detectrtc');

let audioOut = null;

/**
 * Detect if android OS
 * @return {Boolean}
 */
export function isAndroidDevice() {
  return /chrome|android/i.test(navigator.userAgent);
}

/**
 * Detect if tablet device
 * @return {Boolean}
 */
export function isTablet() {
  if (/iPad/i.test(navigator.userAgent)) {
    return true;
  }
  if (/Android/i.test(navigator.userAgent) && !/Mobile/i.test(navigator.userAgent)) {
    return true;
  }

  return false;
}

/**
 * Detect if mobile device
 * @return {Boolean}
 */
export function isMobileDevice() {
  return isMobile;
}

/**
 * Convert date to string
 * @param {Date} date Datetime
 * @param isShortFlow
 * @return {String}
 */
export function dateToString(date, isShortFlow = false) {
  const day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
  const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();

  if (isShortFlow) {
    return `${day}/${month}/${year}`;
  }

  return `${day}-${month}-${year}`;
}

/**
 * Convert monthYear to string
 * @param {Date} date Date Month
 * @param isShortFlow
 * @return {String}
 */
export function monthYearToString(date) {
  const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month}/${year}`;
}

/**
 * Reverse date format
 * @param {String} date Datetime
 * @param {Boolean} toDate response to be date
 * @return {String|Date}
 */
export function reverseDateFormat(date, toDate) {
  if (!date || date === '') {
    if (toDate) {
      return new Date();
    }

    return '';
  }

  const parts = date.split('-');
  const result = `${parts[2]}-${parts[1]}-${parts[0]}`;

  if (toDate) {
    return new Date(result);
  }

  return result;
}

export function reverseDateFormatSF(date, toDate) {
  if (!date || date === '') {
    if (toDate) {
      return new Date();
    }

    return '';
  }

  let parts;
  if (date.includes('-')) {
    parts = date.split('-');
  } else if (date.includes('.')) {
    parts = date.split('.');
  } else {
    parts = date.split('/');
  }

  let result = null;
  if (parts.length === 2) {
    result = `${parts[1]}-${parts[0]}`;
  } else if (parts.length === 3) {
    if (parts[0].length === 4) {
      result = `${parts[0]}-${parts[1]}-${parts[2]}`;
    } else {
      result = `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
  }

  if (toDate) {
    return new Date(result);
  }

  return result;
}

export function convertDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

/**
 * Generate a unique session ID
 * @param  {Number} length The length of the ID
 * @param  {Boolean} split If split using `-`
 * @return {String}
 */
export function generateId(length, split) {
  const s = [];
  let hexDigits = '0123456789abcdef';

  if (split) {
    hexDigits = '0123456789abcdefghidsscklmwxcvbn';
  }

  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }

  s[14] = '4';
  // eslint-disable-next-line no-bitwise
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  const splitString = !split ? '-' : '';
  s[8] = splitString;
  s[13] = splitString;
  s[18] = splitString;
  s[23] = splitString;

  const id = s.join('');

  return id.substring(0, length);
}

/**
 * Create a cookie
 * @param {String} name Cookie name
 * @param {String} value Cookie value
 * @param {Number} exdays Expiry days
 * @return {Void}
 */
export function setCookie(name, value, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);

  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

/**
 * Get cookie by name
 * @param {String} name Cookie name
 * @return {Boolean|String}
 */
export function getCookie(name) {
  const cname = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(cname) === 0) {
      return c.substring(cname.length, c.length);
    }
  }

  return false;
}

/**
 * Initial audio
 * @param {Object} element
 * @return {Object}
 */
export function initAudio(element) {
  // eslint-disable-next-line no-param-reassign
  element.muted = true;
  element.play();

  setTimeout(() => {
    element.pause();
  }, 2000);

  return element;
}

/**
 * Play audio
 * @param {Object} audio
 * @return {Void}
 */
export function playAudio(audio, { from, time }, muted = false) {
  if (audio) {
    // eslint-disable-next-line no-param-reassign
    audio.muted = muted;
    // eslint-disable-next-line no-param-reassign
    audio.currentTime = from;
    audio.play();

    if (audioOut) {
      clearTimeout(audioOut);
    }

    audioOut = setTimeout(() => audio.pause(), time * 1000);
  }
}

/**
 * Get age from date string
 * @param {String} date
 * @param isShortFlow
 */
export function getAge(date, isShortFlow = false) {
  const today = new Date();
  let birthDate = reverseDateFormat(date, true);

  if (isShortFlow) {
    birthDate = reverseDateFormatSF(date, true);
  }

  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

/**
 * Enable location service
 * @return {Void}
 */
export function geoLocation() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        const { latitude, longitude } = coords;
        const geolocation = `${latitude},${longitude}`;

        // Go to next step
        resolve(geolocation);
      },
      () => {
        reject();
      }
    );
  });
}

function browserNotSupportWebRTConIOS() {
  if (!isIOS) {
    return false;
  }

  if (isSafari) {
    return false;
  }

  if (!isChrome) {
    return true;
  }

  // Only support Chrome on iOS version >= 14.3
  if (osVersion !== 'none' && compareVersions.compare(osVersion, '14.3', '<')) {
    return true;
  }

  return false;
}

export function checkWebRTC() {
  if (browserNotSupportWebRTConIOS()) {
    // For iOS, browser must be safari or it should show exception
    return { status: false, todo: 'OPEN_SAFARI' };
  }

  // Now, check if browser supports WebRTC
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    // WebRTC supported
    return { status: true };
  }
  // WebRTC not suppported
  return { status: false, todo: 'NEED_ALT_FLOW' };
}

export async function checkIfCameraEnabled() {
  try {
    await navigator.mediaDevices.getUserMedia({ video: true });
  } catch (err) {
    console.error(err);
    return false;
  }
  return true;
}

export function isBrowserPermitted() {
  if (isAndroidDevice()) {
    if (getUA.toLowerCase().includes('duckduckgo')) {
      return false;
    }
  }

  return !isFirefox;
}

/**
 * Check if webRTC support
 * @return {Object}
 */
export function isWebRTCSupported() {
  return new Promise((resolve) => {
    DetectRTC.load(() => {
      if (CHECK_DEVICE) {
        /// Not working server side device check
        const deviceInfo = getCookie('deviceInfo');

        if (deviceInfo) {
          const { os } = JSON.parse(deviceInfo);

          const result = checkWebRTC();
          result.os = os;

          resolve(result);
        } else {
          APIs.checkDevice().then((res) => {
            setCookie('deviceInfo', JSON.stringify(res), 1);

            const { os } = res;

            const result = checkWebRTC();
            result.os = os;

            resolve(result);
          });
        }
      } else {
        const uaParser = new UAParser();
        const { name: OS, version } = uaParser.getOS();

        const result = checkWebRTC();
        result.os = OS;
        result.version = version;

        resolve(result);
      }
    });
  });
}

export function testDevice() {
  const uaParser = new UAParser();
  const { name: BrowserName } = uaParser.getBrowser();
  const { name: OS, version } = uaParser.getOS();

  return { BrowserName, OS, version };
}

/**
 * Copy to clipboard for iOS only
 * @param {String} value
 * @param input
 * @return {Void}
 */
export function copyToClipboard(value, input) {
  // eslint-disable-next-line no-param-reassign
  input.value = value;

  const range = document.createRange();
  range.selectNodeContents(input);

  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);

  input.setSelectionRange(0, 999999);

  document.execCommand('copy');
}

/**
 * Copy & Open mobile safari
 * @param {String} input
 */
export function copyOpenSafari(input) {
  const { id } = document.body.dataset;
  copyToClipboard(document.location.href + id, input);
  window.open('x-web-search://', '_self');
}

/**
 * Handle classes
 * @param classes
 * @param value
 * @return {String}
 */
export function wrapperClasses(classes, value) {
  return value.split(' ').map((c) => {
    if (c.match(/style/i)) {
      return c;
    }
    if (classes[c]) {
      return `${classes[c]} `;
    }

    return c;
  });
}

/**
 * Detect landscape
 * @return {Boolean}
 */
export function isLandscape() {
  return window.innerHeight < window.innerWidth;
}

/**
 * Validate date
 * @return {Boolean}
 */
export function validateDate(value) {
  return Moment(value, 'DD-MM-YYYY', true).isValid();
}

/**
 * get Full Address from detailed address
 * @return {string}
 */
export function getResAddressValue(data, iso2CountryCode) {
  const defaultAddressConcatenation = [
    'addressLine1',
    'addressLine2',
    'suburb',
    'stateTerritory',
    'postcode'
  ];
  const addressConcatenationByCountry = {
    JP: ['postcode', 'suburb', 'addressLine1', 'addressLine2']
  };

  const addressConcatenationOrder =
    addressConcatenationByCountry[iso2CountryCode] || defaultAddressConcatenation;

  return addressConcatenationOrder
    .flatMap((key) => {
      if ((data?.[key] || '').trim()) {
        return data[key].trim();
      }
      return [];
    })
    .join(' ');
}

/**
 * Extract Query parameters
 * @return {string}
 */
export function getQueryStringParams() {
  const query = window.location.search;

  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        const [key, value] = param.split('=');
        // eslint-disable-next-line no-param-reassign
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {};
}

/**
 * convert camelCase to Camel Case
 * @return {string}
 */
export function camelCaseToLabel(str) {
  if (!str) return str;
  if (str === 'idNumber') return 'ID Number';
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
    return str.toUpperCase();
  });
}

/**
 * convert camelCase to Initial Capital
 * @return {string}
 */
export function camelCaseToInitialCapital(str) {
  if (!str) return str;
  if (str === 'idNumber') return 'ID number';
  return str
    .replace(/([A-Z])/g, ' $1')
    .toLowerCase()
    .replace(/^./, (str) => {
      return str.toUpperCase();
    });
}

/**
 * compare two versions
 * https://gist.github.com/pc035860/ccb58a02f5085db0c97d
 * @return {string}
 */

export function versionCompare(v1, v2, options) {
  const lexicographical = options && options.lexicographical;
  const zeroExtend = options && options.zeroExtend;
  let v1parts = v1.split('.');
  let v2parts = v2.split('.');

  function isValidPart(x) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push('0');
    while (v2parts.length < v1parts.length) v2parts.push('0');
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number);
    v2parts = v2parts.map(Number);
  }

  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] === v2parts[i]) {
      // eslint-disable-next-line no-continue
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length !== v2parts.length) {
    return -1;
  }

  return 0;
}

export function isShownPrivacy(flowType) {
  let privacy = true;
  if (HIDDEN_PRIVACY_IN === 'ALL') {
    privacy = false;
  } else if (
    HIDDEN_PRIVACY_IN &&
    HIDDEN_PRIVACY_IN.length > 0 &&
    HIDDEN_PRIVACY_IN.includes(flowType)
  ) {
    privacy = false;
  }

  return privacy && flowType !== 'LIVE_ONLY';
}

export function getProjectName() {
  const { project = '' } = document.body.dataset;
  return project;
}
