import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Title } from '@lib/components/v2/Title';
import validator from '@rjsf/validator-ajv8';

import { Button } from '@lib/components/v2/Form';
import Page from '@lib/components/v2/Page';
import FormBootstrap3 from '@rjsf/core';
import { Form as FormBootstrap4 } from '@rjsf/bootstrap-4';
import parse from 'html-react-parser';

import { localizedString } from '@languages';
import CustomCheckboxes from './CustomCheckboxes';
import classes from './QuestionnaireForm.style.module.scss';
import { CustomMultipleSelectWidget } from './CustomMultipleSelectWidget';

export default class QuestionnaireForm extends Component {
  static propTypes = {
    schemas: PropTypes.object,
    uiSchemas: PropTypes.object,
    onSubmit: PropTypes.func,
    onExit: PropTypes.func,
    enableQuestionnaireReview: PropTypes.bool,
    schemaValidationErrors: PropTypes.object,
    theme: PropTypes.string,
    enableExitButton: PropTypes.bool
  };

  constructor(props) {
    super(props);

    const { schemas, uiSchemas } = props;
    const schemaKeys = Object.keys(schemas);
    const uiSchemaKeys = Object.keys(uiSchemas);

    this.state = {
      step: 0,
      formData: {},
      schemaKeys,
      uiSchemaKeys,
      stepSchema: schemas[schemaKeys[0]],
      stepUischema: uiSchemas[uiSchemaKeys[0]],
      showReview: false
    };

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handlePreviousStep = this.handlePreviousStep.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReedit = this.handleReedit.bind(this);
  }

  handleNextStep() {
    const { schemas, uiSchemas } = this.props;
    const { step, schemaKeys, uiSchemaKeys } = this.state;

    const nextStep = step + 1;
    const nextStepSchema = schemas[schemaKeys[step + 1]];
    const nextStepUichema = uiSchemas[uiSchemaKeys[step + 1]];

    this.setState({
      step: nextStep,
      stepSchema: nextStepSchema,
      stepUischema: nextStepUichema
    });
  }

  handleLastStep() {
    const { enableQuestionnaireReview } = this.props;
    if (enableQuestionnaireReview) {
      this.setState({
        showReview: true
      });
    } else {
      this.handleSubmit();
    }
  }

  handlePreviousStep() {
    if (this.isFirstStep()) {
      const { onExit } = this.props;

      onExit();
      return;
    }

    const { schemas, uiSchemas } = this.props;
    const { step, schemaKeys, uiSchemaKeys } = this.state;

    this.setState({
      step: step - 1,
      stepSchema: schemas[schemaKeys[step - 1]],
      stepUischema: uiSchemas[uiSchemaKeys[step - 1]]
    });
  }

  handleChange({ formData }) {
    this.setState({ formData });
  }

  handleSubmit() {
    const { onSubmit } = this.props;
    const { formData } = this.state;
    onSubmit(formData);
  }

  handleReedit(formKey) {
    const { schemas, uiSchemas } = this.props;
    this.setState({
      showReview: false,
      stepSchema: schemas[formKey],
      stepUischema: uiSchemas[formKey]
    });
  }

  isFirstStep() {
    const { step } = this.state;
    return step === 0;
  }

  isLastStep() {
    const { step, schemaKeys } = this.state;

    return step === schemaKeys.length - 1;
  }

  showReviewContent() {
    const { uiSchemas } = this.props;
    const { formData } = this.state;
    const content = Object.keys(uiSchemas).map((formKey) => {
      const formReview = this.renderFieldItem(formData, uiSchemas[formKey], formKey);

      return (
        <div className={classNames(classes.questionsGroup)} key={formKey}>
          <div className={classNames(classes.questions)}>{formReview}</div>
          <div className={classNames(classes.edit)}>
            <u
              role="presentation"
              onClick={() => {
                this.handleReedit(formKey);
              }}
            >
              Edit
            </u>
          </div>
        </div>
      );
    });

    return content;
  }

  renderFieldItem(parentFormData, parentUiSchema, classKey) {
    return Object.keys(parentUiSchema).map((key) => {
      if (key === '*') {
        // when key is *, loop through form data with same ui schema
        return parentFormData.map((itemData, index) => {
          return this.renderFieldItem(itemData, parentUiSchema[key], classKey + index);
        });
      }

      if (!parentFormData[key]) {
        return null;
      }

      const childClassKey = classKey + key;

      if (parentUiSchema[key]['ui:title']) {
        return (
          <div key={childClassKey} className={classNames(classes.question)}>
            <div className={classNames(classes.title)}>{parentUiSchema[key]['ui:title']}</div>
            <div className={classNames(classes.answer)}>
              {Array.isArray(parentFormData[key])
                ? parentFormData[key].join(', ')
                : parentFormData[key]}
            </div>
          </div>
        );
      }

      return this.renderFieldItem(parentFormData[key], parentUiSchema[key], childClassKey);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderImportantInfo() {
    return (
      <div className={classNames(classes.importantInfo)}>
        <h4>{localizedString('app.questionnaire.importantInfo.heading')}</h4>
        <p>{parse(localizedString('app.questionnaire.importantInfo.description'))}</p>
      </div>
    );
  }

  render() {
    const {
      schemaValidationErrors = {},
      enableExitButton,
      enableQuestionnaireReview,
      theme
    } = this.props;
    const { formData, showReview, stepSchema, stepUischema } = this.state;

    const widgets = {
      CheckboxesWidget: CustomCheckboxes,
      MultipleSelectWidget: CustomMultipleSelectWidget
    };

    const transformErrors = (errorList) => {
      return errorList.map((error) => {
        const errorPropertyName = error.property
          .split('.')
          .map((field) => {
            if (field.length > 0 && !Number.isNaN(Number(field))) {
              return '*';
            }
            return field;
          })
          .join('.');

        if (
          schemaValidationErrors[errorPropertyName] &&
          schemaValidationErrors[errorPropertyName][error.name]
        ) {
          return {
            ...error,
            message: schemaValidationErrors[errorPropertyName][error.name]
          };
        }
        return error;
      });
    };

    const FormComponent = theme === 'bootstrap-3' ? FormBootstrap3 : FormBootstrap4;

    return (
      <Page isMessage>
        {!showReview && (
          <FormComponent
            showErrorList={false}
            widgets={widgets}
            validator={validator}
            transformErrors={transformErrors}
            schema={stepSchema}
            uiSchema={stepUischema}
            formData={formData}
            onChange={this.handleChange}
            onSubmit={() => {
              if (this.isLastStep()) {
                this.handleLastStep();
              } else {
                this.handleNextStep();
              }
            }}
            noHtml5Validate
          >
            <div className={classes.footer}>
              <div className={classes.buttonsWrapper}>
                <div className={classes.buttons}>
                  <Button
                    type="button"
                    onClick={this.handlePreviousStep}
                    label={localizedString('back')}
                    variant="transparent"
                    disabled={!enableExitButton && this.isFirstStep()}
                  />

                  {!this.isLastStep() && <Button type="submit" label="Next" />}

                  {this.isLastStep() && (
                    <Button
                      type="submit"
                      label={enableQuestionnaireReview ? 'Preview' : 'Submit'}
                    />
                  )}
                </div>
              </div>
            </div>
          </FormComponent>
        )}
        {showReview && (
          <div className={classNames(classes.review)}>
            <div className={classNames(classes.header)}>
              <Title title={localizedString('reviewAndSubmit')} />
            </div>
            <div className={classNames(classes.questionsGroups)}>
              {this.showReviewContent()}
              {this.renderImportantInfo()}
            </div>
            <div className={classes.footer}>
              <div className={classes.buttonsWrapper}>
                <div className={classes.buttons}>
                  <Button
                    type="button"
                    label={localizedString('back')}
                    variant="transparent"
                    onClick={() => {
                      this.setState({ showReview: false });
                    }}
                  />
                  <Button type="button" label="Submit" onClick={this.handleSubmit} />
                </div>
              </div>
            </div>
          </div>
        )}
      </Page>
    );
  }
}
