import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Validation } from 'calidation';
import { localizedString } from '@languages';
import { getResAddressValue } from '@lib/Utils';
import { getCountryLabelFromIso2Code, getStatesFromCountry } from '@lib/countryUtils';
import { CountrySelect, Input, Select } from '@lib/components/v2/Form';
import { useManualAddressFields } from './useManualAddressFields';
import classes from './AddressFinder.style.module.scss';

export const ManualAddress = ({
  initialFieldsData = {},
  availableIso3Countries,
  onChange,
  disabled
}) => {
  const [selectedManualCountryCode, setSelectedManualCountryCode] = useState(
    initialFieldsData?.selectedManualCountryCode || 'AU'
  );

  const country = {
    value: selectedManualCountryCode,
    label: getCountryLabelFromIso2Code(selectedManualCountryCode)
  };

  const [detailedAddress, setDetailedAddress] = useState({
    ...initialFieldsData,
    country: country.label
  });

  const states = getStatesFromCountry(selectedManualCountryCode);

  const { fields, validations } = useManualAddressFields(selectedManualCountryCode);

  const handleChangeAddress = (addressFields) => {
    const {
      addressLine1,
      addressLine2,
      suburb,
      postcode,
      state_territory: stateTerritory
    } = addressFields;

    const homeAddress = getResAddressValue(
      {
        addressLine1,
        addressLine2,
        suburb,
        postcode,
        stateTerritory
      },
      selectedManualCountryCode
    );

    const data = {
      ...addressFields,
      city: suburb,
      homeAddress,
      fullAddress: homeAddress,
      isMatch: false,
      manual: true
    };

    onChange(data);

    setDetailedAddress(addressFields);
  };

  useEffect(function notifyInitialManualAddressToParent() {
    handleChangeAddress(detailedAddress);
  }, []);

  return (
    <Validation
      initialValues={detailedAddress}
      key={selectedManualCountryCode}
      config={validations}
    >
      {({ errors, setField }) => (
        <div className={classes.detailedWrapper}>
          <div className={classes.countries}>
            <CountrySelect
              filter={availableIso3Countries}
              value={country}
              onChange={({ value, label }) => {
                setSelectedManualCountryCode(value);

                const detailedAddr = { ...detailedAddress };
                detailedAddr.country = label;
                detailedAddr.selectedManualCountryCode = value;
                handleChangeAddress(detailedAddr);
                setField({ country: label });
              }}
            />
          </div>

          {fields.map(({ name, placeholder }) => (
            <Input
              type="text"
              key={name}
              id={name}
              placeholder={placeholder}
              className={classes.input}
              hasError={errors[name]}
              onChange={(value) => {
                const detailedAddr = { ...detailedAddress };
                detailedAddr[name] = value;
                handleChangeAddress(detailedAddr);
                setField({ [name]: value });
              }}
              value={detailedAddress[name] || ''}
              disabled={disabled}
            />
          ))}

          <div className={classes.inputGroup}>
            {selectedManualCountryCode === 'AU' && (
              <div data-testid="manual_state_territory" style={{ position: 'relative' }}>
                <Select
                  id="state_territory"
                  borderBottomOnly
                  placeholder={localizedString('state')}
                  hasError={errors.state_territory}
                  options={states}
                  onChange={(state) => {
                    const detailedAddr = { ...detailedAddress };
                    detailedAddr.state_territory = state.value;
                    handleChangeAddress(detailedAddr);
                    setField({ state_territory: state.value });
                  }}
                  value={
                    detailedAddress.state_territory
                      ? {
                          label: detailedAddress.state_territory,
                          value: detailedAddress.state_territory
                        }
                      : null
                  }
                  disabled={disabled}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Validation>
  );
};

ManualAddress.propTypes = {
  initialFieldsData: PropTypes.object,
  availableIso3Countries: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};
