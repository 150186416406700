import React from 'react';
import PropTypes from 'prop-types';
import classes from './LoadingBar.style.module.scss';

const LoadingBar = ({ width }) => {
  return (
    <div
      className={classes.progress}
      data-testid="id-statusBar"
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={width}
      aria-valuetext={`${Math.round(width)}%`}
    >
      <div className={classes.bar} style={{ width: `${width}%` }} />
    </div>
  );
};

LoadingBar.propTypes = {
  width: PropTypes.number
};

LoadingBar.defaultProps = {};

export default LoadingBar;
