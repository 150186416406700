import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import parse from 'html-react-parser';

import { Title } from '@lib/components/v2/Title';
import Page from '@lib/components/v2/Page';
import { DOCUMENTS } from '@lib/constants/documents';
import { TickIcon, UncheckedRadioIcon } from '@lib/components/v2/icons';
import { TICK_ICON_COLOR } from '@spotMobileConfig';

import classes from './DocumentListScreen.style.module.scss';

export const DocumentListScreen = ({
  title,
  description,
  documentList,
  onClickDocumentItem,
  footerButtons,
  progressBarPercentage,
  hideDocImages = false
}) => {
  return (
    <Page buttons={footerButtons}>
      <div className={classes.wrapper}>
        <Title data-testid="docs-heading" title={title} />
        <div className={classes.description} data-testid="docs-instruction">
          {parse(description ?? '')}
        </div>
        <div className={classes.document_list}>
          {documentList.map((item, index) => {
            return (
              <label
                key={item.title}
                className={classNames(classes.document_item, item.checked && classes.checked)}
                htmlFor={`docs-${index}`}
              >
                <input
                  id={`docs-${index}`}
                  name={`docs-${index}`}
                  type="checkbox"
                  onClick={() => onClickDocumentItem(item, index)}
                />
                <div
                  className={classNames(
                    classes.check_mark_wrapper,
                    item.checked && classes.checked
                  )}
                  data-testid={`docs-radio-${index}`}
                >
                  {item.checked ? <TickIcon color={TICK_ICON_COLOR} /> : <UncheckedRadioIcon />}
                </div>
                <div className={classes.document_title} data-testid={`docs-doc-${index}`}>
                  {item.title}
                </div>
                {!hideDocImages && (
                  <div className={classes.card_wrapper} data-testid={`docs-img-${index}`}>
                    <img
                      className={classNames(
                        classes.img_card,
                        item.type === DOCUMENTS.PASSPORT.documentType ||
                          item.cardType === DOCUMENTS.PASSPORT.cardType
                          ? classes.passport
                          : classes.card
                      )}
                      src={`images/icons/svg/${item.icon}`}
                      alt=""
                    />
                  </div>
                )}
              </label>
            );
          })}
        </div>
        {progressBarPercentage > 0 && (
          <div className={classes.progress_bar} style={{ width: `${progressBarPercentage}%` }} />
        )}
      </div>
    </Page>
  );
};

DocumentListScreen.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  documentList: PropTypes.array,
  onClickDocumentItem: PropTypes.func,
  footerButtons: PropTypes.array,
  progressBarPercentage: PropTypes.number,
  hideDocImages: PropTypes.bool
};
