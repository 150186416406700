import PropTypes from 'prop-types';
import Modal from '@lib/components/v2/Modal';
import { localizedString } from '@languages';

export const ConfirmModal = ({
  heading,
  children,
  cancelTextButton,
  confirmTextButton,
  onCancel,
  onConfirm
}) => {
  const confirmBtns = [
    {
      label: cancelTextButton || localizedString('app.FLOW_V2_EXIT_SCREEN_CANCEL_BUTTON'),
      onClick: () => onCancel(),
      variant: 'transparent',
      autoFocus: true
    },
    {
      label: confirmTextButton || localizedString('app.FLOW_V2_EXIT_SCREEN_OK_BUTTON'),
      onClick: () => onConfirm()
    }
  ];

  return (
    <Modal isOpen heading={heading} buttons={confirmBtns}>
      {children}
    </Modal>
  );
};

ConfirmModal.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node,
  cancelTextButton: PropTypes.string,
  confirmTextButton: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};
