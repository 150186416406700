import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { getProjectName } from '@lib/Utils';
import { trimHtml } from '@lib/utils/format';
import parse from 'html-react-parser';

import classes from './Title.style.module.scss';

export const Title = ({ title = '', className, ...props }) => {
  let displayTitle;
  let pageTitle;
  try {
    displayTitle = parse(title);
    pageTitle = trimHtml(title);
  } catch (e) {
    displayTitle = title;
  }

  return (
    <>
      <h1 {...props} className={classNames(classes.heading, className)}>
        {displayTitle}
      </h1>
      {pageTitle && (
        <Helmet>
          <title>
            {getProjectName()} - {pageTitle}
          </title>
        </Helmet>
      )}
    </>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  className: PropTypes.any
};
