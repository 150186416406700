import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import { useFlowTypeContext } from '@js/lib/components/v2/App/FlowTypeProvider';
import { ANIMATIONS } from '@js/constants/resourcesPaths';
import { ANIMATION_FILE_URLS_EXTEND, ANIMATION_OVERWRITTEN_BY_FLOW } from '@spotMobileConfig';
import Animation from './Animation';

const ANIMATION_FILE_URLS = {
  ...ANIMATIONS,
  ...ANIMATION_FILE_URLS_EXTEND
};

export default Animation;

export const useAnimationUrls = () => {
  const { flowType } = useFlowTypeContext();

  const animationUrls = useMemo(() => {
    if (
      !isEmpty(ANIMATION_OVERWRITTEN_BY_FLOW?.[flowType]) &&
      isPlainObject(ANIMATION_OVERWRITTEN_BY_FLOW[flowType])
    ) {
      return {
        ...ANIMATION_FILE_URLS,
        ...ANIMATION_OVERWRITTEN_BY_FLOW[flowType]
      };
    }

    return ANIMATION_FILE_URLS;
  }, [flowType]);

  return { animationUrls };
};

export const withAnimationUrls = (OriginalComponent) => {
  function WithAnimationUrls(props) {
    const { animationUrls } = useAnimationUrls();
    return <OriginalComponent {...props} animationUrls={animationUrls} />;
  }

  return WithAnimationUrls;
};
