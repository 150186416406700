import { getLanguage } from '@languages';
import { VOICE_PROMPTS_SYNC } from '@spotMobileConfig';

const voicePromptsParams = [
  {
    default: true,
    language: 'en',
    ogg: 'media/audio/en-au.ogg',
    mp3: 'media/audio/en-au.mp3',
    durations: {
      position: { from: 0, to: 4 },
      smile: { from: 5, to: 6.2 },
      biggerSmile: { from: 7, to: 8.2 },
      thanks: { from: 9, to: 10 },
      ...(VOICE_PROMPTS_SYNC?.en || {})
    }
  },
  {
    language: 'en-uk',
    ogg: 'media/audio/English_UK.ogg',
    mp3: 'media/audio/English_UK.mp3',
    durations: {
      position: { from: 0, to: 2.5 },
      smile: { from: 3.1, to: 4.5 },
      biggerSmile: { from: 5.2, to: 6.5 },
      thanks: { from: 7.2, to: 8 },
      ...(VOICE_PROMPTS_SYNC?.uk || {})
    }
  },
  {
    language: 'en-us',
    ogg: 'media/audio/en-us.ogg',
    mp3: 'media/audio/en-us.mp3',
    durations: {
      position: { from: 0.6, to: 2.7 },
      smile: { from: 4.5, to: 6.0 },
      biggerSmile: { from: 7, to: 9 },
      thanks: { from: 10, to: 11 },
      ...(VOICE_PROMPTS_SYNC?.['en-us'] || {})
    }
  },
  {
    language: 'zh',
    ogg: 'media/audio/chinese.ogg',
    mp3: 'media/audio/chinese.mp3',
    durations: {
      position: { from: 0, to: 2.5 },
      smile: { from: 3.7, to: 5.1 },
      biggerSmile: { from: 6.5, to: 7.6 },
      thanks: { from: 9.6, to: 10.3 },
      ...(VOICE_PROMPTS_SYNC?.zh || {})
    }
  },
  {
    language: 'zhh',
    ogg: 'media/audio/cantonese.ogg',
    mp3: 'media/audio/cantonese.mp3',
    durations: {
      position: { from: 0, to: 3 },
      smile: { from: 4, to: 6.5 },
      biggerSmile: { from: 7, to: 9.5 },
      thanks: { from: 10, to: 10.8 },
      ...(VOICE_PROMPTS_SYNC?.zhh || {})
    }
  },
  {
    language: 'tr',
    ogg: 'media/audio/turkish.ogg',
    mp3: 'media/audio/turkish.mp3',
    durations: {
      position: { from: 0, to: 4 },
      smile: { from: 5, to: 6 },
      biggerSmile: { from: 7, to: 8.6 },
      thanks: { from: 10, to: 11 },
      ...(VOICE_PROMPTS_SYNC?.tr || {})
    }
  },
  {
    language: 'kk',
    ogg: 'media/audio/kazakh.ogg',
    mp3: 'media/audio/kazakh.mp3',
    durations: {
      position: { from: 0, to: 2.5 },
      smile: { from: 2.5, to: 4 },
      biggerSmile: { from: 2.5, to: 4 },
      thanks: { from: 15, to: 16 },
      ...(VOICE_PROMPTS_SYNC?.kk || {})
    }
  },
  {
    language: 'ru',
    ogg: 'media/audio/russian.ogg',
    mp3: 'media/audio/russian.mp3',
    durations: {
      position: { from: 0, to: 3 },
      smile: { from: 3.6, to: 5 },
      biggerSmile: { from: 3.6, to: 5 },
      thanks: { from: 20.3, to: 22 },
      ...(VOICE_PROMPTS_SYNC?.ru || {})
    }
  },
  {
    language: 'ar',
    ogg: 'media/audio/arabic.ogg',
    mp3: 'media/audio/arabic.mp3',
    durations: {
      position: { from: 0, to: 2 },
      smile: { from: 3, to: 5 },
      biggerSmile: { from: 6, to: 8 },
      thanks: { from: 9, to: 10.5 },
      ...(VOICE_PROMPTS_SYNC?.ar || {})
    }
  },
  {
    language: 'hi',
    ogg: 'media/audio/hindi.ogg',
    mp3: 'media/audio/hindi.mp3',
    durations: {
      position: { from: 0, to: 3.2 },
      smile: { from: 4.3, to: 6 },
      biggerSmile: { from: 7, to: 8.8 },
      thanks: { from: 10, to: 11 },
      ...(VOICE_PROMPTS_SYNC?.hi || {})
    }
  },
  {
    language: 'jp',
    ogg: 'media/audio/japanese.ogg',
    mp3: 'media/audio/japanese.mp3',
    durations: {
      position: { from: 0, to: 3 },
      smile: { from: 4, to: 5.9 },
      biggerSmile: { from: 7, to: 9.2 },
      thanks: { from: 10, to: 11.7 },
      ...(VOICE_PROMPTS_SYNC?.jp || {})
    }
  },
  {
    language: 'es',
    ogg: 'media/audio/spanish.ogg',
    mp3: 'media/audio/spanish.mp3',
    durations: {
      position: { from: 0, to: 2.1 },
      smile: { from: 3, to: 4.8 },
      biggerSmile: { from: 5.1, to: 7.8 },
      thanks: { from: 8, to: 8.9 },
      ...(VOICE_PROMPTS_SYNC?.es || {})
    }
  },
  {
    language: 'id',
    ogg: 'media/audio/indonesian.ogg',
    mp3: 'media/audio/indonesian.mp3',
    durations: {
      position: { from: 0, to: 3.4 },
      smile: { from: 6, to: 7.7 },
      biggerSmile: { from: 10, to: 12 },
      thanks: { from: 14.8, to: 16 },
      ...(VOICE_PROMPTS_SYNC?.id || {})
    }
  },
  {
    language: 'pt',
    ogg: 'media/audio/portuguese.ogg',
    mp3: 'media/audio/portuguese.mp3',
    durations: {
      position: { from: 0, to: 2.9 },
      smile: { from: 4, to: 5.8 },
      biggerSmile: { from: 7.1, to: 8.6 },
      thanks: { from: 9, to: 9.9 },
      ...(VOICE_PROMPTS_SYNC?.pt || {})
    }
  },
  {
    language: 'ms',
    ogg: 'media/audio/malay.ogg',
    mp3: 'media/audio/malay.mp3',
    durations: {
      position: { from: 0.3, to: 3 },
      smile: { from: 4.3, to: 6 },
      biggerSmile: { from: 7.3, to: 9.4 },
      thanks: { from: 10.2, to: 11.3 },
      ...(VOICE_PROMPTS_SYNC?.ms || {})
    }
  },
  {
    language: 'tl',
    ogg: 'media/audio/filipino.ogg',
    mp3: 'media/audio/filipino.mp3',
    durations: {
      position: { from: 1.0, to: 3.4 },
      smile: { from: 4.8, to: 6.7 },
      biggerSmile: { from: 8.6, to: 10.6 },
      thanks: { from: 11.7, to: 12.5 },
      ...(VOICE_PROMPTS_SYNC?.tl || {})
    }
  },
  {
    language: 'fr',
    ogg: 'media/audio/french.ogg',
    mp3: 'media/audio/french.mp3',
    durations: {
      position: { from: 0.1, to: 2.5 },
      smile: { from: 3.2, to: 4.7 },
      biggerSmile: { from: 6.1, to: 8.1 },
      thanks: { from: 9.1, to: 9.9 },
      ...(VOICE_PROMPTS_SYNC?.fr || {})
    }
  }
];

export const getVoicePromptInfo = () => {
  const language = getLanguage();

  let promptInfo = voicePromptsParams.find((item) => item.language === language);
  if (!promptInfo) {
    promptInfo = voicePromptsParams.find((item) => item.default);
  }

  return promptInfo;
};
