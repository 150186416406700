export const getCustomStyles = ({ borderBottomOnly, classes, paddingLeft30, isOpened }) => {
  let borderStyle = '1px solid rgba(25, 25, 25, 0.32)';

  if (borderBottomOnly) {
    borderStyle = 'none';
  }

  const selectedLabelStyle = {
    top: '1px',
    left: '0px',
    zIndex: '1',
    fontSize: '1rem', // 15
    fontWeight: '400'
  };

  const focusedLabelStyle = {
    position: 'absolute',
    top: '-15px',
    left: '-11px',
    transform: 'translateY(-50%) scale(0.9)',
    fontWeight: '400',
    padding: '0 0.3rem',
    margin: '0 0.5rem',
    transition: '.1s ease-out',
    transformOrigin: 'left top',
    pointerEvents: 'none',
    backgroundColor: '#fff',
    color: isOpened ? classes.secondary : '#6E7396'
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#000',
      borderBottom: state.isSelected ? 'none !important' : 'initial',
      backgroundColor: state.isSelected ? classes.primary50 : 'transparent',
      textAlign: 'left'
    }),
    control: (provided) => ({
      ...provided,
      width: '100%',
      fontSize: '1rem', // 15
      minHeight: '3.75rem',
      outline: 'none',
      boxShadow: 'none',
      backgroundColor: '#fff',
      border: borderStyle,
      borderBottom: borderBottomOnly ? '1px solid rgba(25, 25, 25, 0.32)' : null,
      borderRadius: borderBottomOnly ? '0px' : '5px',
      padding: '0.5rem 0.7rem',
      color: '#394448',
      '> div': {
        overflow: 'hidden',
        padding: 0
      },
      paddingLeft: paddingLeft30 ? '35%' : 'auto'
    }),
    placeholder: (provided) => {
      let plCss = isOpened
        ? focusedLabelStyle
        : { left: '-3px', transition: '0.1s ease-out', color: '#6E7396', fontWeight: '400' };
      plCss = borderBottomOnly ? {} : plCss;
      return {
        ...provided,
        ...plCss
      };
    },
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    indicatorsContainer: (provided) => {
      const rotate = isOpened
        ? { transform: 'rotate(180deg)', transition: '0.1s ease-out' }
        : { transition: '0.1s ease-out' };
      return {
        ...provided,
        '> div': {
          color: '#A5ABC1'
        },
        ...rotate
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        padding: '0',
        margin: '0',
        borderRadius: '0',
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0), 0 6px 6px rgba(0, 0, 0, 0.11)',
        marginLeft: paddingLeft30 ? '35%' : 'auto',
        width: paddingLeft30 ? '65%' : 'auto'
      };
    },
    menuList: (provided) => {
      return {
        ...provided,
        padding: '0',
        div: {
          padding: '0.8125rem', // 13
          borderBottom: '1px solid #E8E8EC',
          fontSize: '1rem', // 15
          fontWeight: '400'
        },
        'div:last-child': {
          borderBottom: 'none'
        }
      };
    },
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        fontSize: '1rem',
        lineHeight: '1.2',
        opacity,
        transition
      };
    }
  };

  return {
    customStyles,
    focusedLabelStyle,
    selectedLabelStyle
  };
};
