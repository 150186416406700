import { useMemo } from 'react';
import { localizedString } from '@languages';
import { defaultLettersAndSymbolsRegexMatch } from '@lib/validations/vefifyDetailsFormValidations';

export const useManualAddressFields = (iso2CountryCode) => {
  const fields = useMemo(() => {
    const orderOfFieldsByCountry = {
      JP: ['postcode', 'suburb', 'addressLine1', 'addressLine2']
    };

    const orderOfFields = orderOfFieldsByCountry[iso2CountryCode] || [
      'addressLine1',
      'addressLine2',
      'suburb',
      'postcode'
    ];

    const defaultFields = {
      addressLine1: {
        placeholder: localizedString('addressLine1')
      },
      addressLine2: {
        placeholder: localizedString('addressLine2')
      },
      suburb: {
        placeholder: localizedString('suburbOrCity')
      },
      postcode: {
        placeholder: localizedString('postcode')
      }
    };

    return orderOfFields.map((fieldName) => ({
      name: fieldName,
      placeholder: defaultFields[fieldName].placeholder
    }));
  }, [iso2CountryCode]);

  const validations = useMemo(() => {
    const overwrittenValidationsByCountry = {
      JP: {
        postcode: {
          isRequired: {
            message: `${localizedString('postcode')} ${localizedString('isRequired')}`
          },
          isMaxLength: {
            message: localizedString('postcodeLengthLimit', 9),
            length: 9
          },
          isRegexMatch: defaultLettersAndSymbolsRegexMatch
        }
      }
    };

    const defaultValidations = {
      addressLine1: {
        isRequired: {
          message: `${localizedString('addressLine1')} ${localizedString('isRequired')}`
        },
        isRegexMatch: defaultLettersAndSymbolsRegexMatch
      },
      addressLine2: {
        isRegexMatch: defaultLettersAndSymbolsRegexMatch
      },
      suburb: {
        isRequired: {
          message: `${localizedString('suburbOrCity')} ${localizedString('isRequired')}`
        },
        isRegexMatch: defaultLettersAndSymbolsRegexMatch
      },
      postcode: {
        isMaxLength: {
          message: localizedString('postcodeLengthLimit', 8),
          length: 8
        },
        isRegexMatch: {
          message: localizedString('lettersAndNumbersOnly'),
          regex: /^([a-zA-Z0-9]+)$/
        }
      },
      state_territory: {
        isRequired: {
          message: localizedString('stateRequired'),
          validateIf: ({ fields }) => fields.country === 'Australia'
        }
      },
      country: {
        isRequired: localizedString('countryRequired')
      }
    };

    return { ...defaultValidations, ...(overwrittenValidationsByCountry[iso2CountryCode] || {}) };
  }, [iso2CountryCode]);

  return { fields, validations };
};
