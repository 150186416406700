import { useSelector } from 'react-redux';
import camelCase from 'lodash/camelCase';
import omitBy from 'lodash/omitBy';
import { isLocalizedStringDefined, localizedString } from '@languages';
import { useFlowTypeContext } from '@js/lib/components/v2/App/FlowTypeProvider';

const getCardObject = (card) => {
  const camelCasedDocKey = camelCase(card);
  const displayCardName = isLocalizedStringDefined(camelCasedDocKey)
    ? localizedString(camelCasedDocKey)
    : card;

  return {
    label: displayCardName,
    type: card
  };
};

export const useAllowedDocumentsInIDVC = (iso3Country = null) => {
  const appConfig = useSelector(({ appConfig }) => appConfig);
  const { flowType } = useFlowTypeContext();

  const { acceptedDocs, acceptedDocCountryCombo } = appConfig.engine4[flowType];
  let cardTypes = {};

  // acceptedDocs is deprecated. acceptedDocCountryCombo should be the only way to config.
  // Having this until BE remove that config
  if (acceptedDocs && acceptedDocs.length > 0) {
    cardTypes = acceptedDocs.reduce((result, card) => {
      return { ...result, [card]: getCardObject(card) };
    }, {});
  }

  if (acceptedDocCountryCombo && Object.keys(acceptedDocCountryCombo).length > 0) {
    cardTypes = Object.keys(acceptedDocCountryCombo).reduce((result, key) => {
      if (acceptedDocCountryCombo[key] === 'NONE') {
        return result;
      }

      if (
        acceptedDocCountryCombo[key] === 'ALL' ||
        !iso3Country ||
        (iso3Country && acceptedDocCountryCombo[key].includes(iso3Country))
      ) {
        return {
          ...result,
          [key]: getCardObject(key)
        };
      }

      return result;
    }, {});
  }

  const isDigitalIdEnabled = Object.keys(cardTypes).some((type) => type.match(/digital/i));
  if (isDigitalIdEnabled) {
    cardTypes = omitBy(cardTypes, (_, type) => type.match(/digital/i));
  }

  return {
    cardTypeObject: cardTypes,
    isDigitalIdEnabled
  };
};
