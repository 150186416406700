import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, ButtonGroup } from '@lib/components/v2/Form';
import { wrapperClasses } from '@lib/Utils';
import { ENABLE_BACK_BUTTON_ON_HEADER, BACK_BUTTON_ICON_ON_HEADER } from '@spotMobileConfig';
import Header from './Header';
import classes from './Page.style.module.scss';

class Page extends Component {
  static propTypes = {
    isPrivacy: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    footerShadow: PropTypes.bool,
    hideLogo: PropTypes.bool,
    isDark: PropTypes.bool,
    isMessage: PropTypes.bool,
    forceFillViewPort: PropTypes.bool,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
        shadow: PropTypes.bool,
        large: PropTypes.bool
      })
    ),
    onSelectLanguage: PropTypes.func,
    ariaLive: PropTypes.oneOf(['none', 'assertive', 'polite']),
    containerProps: PropTypes.object
  };

  static defaultProps = {
    isPrivacy: false,
    className: '',
    buttons: [],
    footerShadow: false,
    hideLogo: false,
    isDark: false,
    isMessage: false,
    forceFillViewPort: false,
    onSelectLanguage: () => null,
    ariaLive: 'polite'
  };

  constructor(props) {
    super(props);
    this.state = {
      isPageScrollable: false
    };
  }

  componentDidMount() {
    const { footerShadow, buttons } = this.props;
    const height = this.pageContent.clientHeight;
    let scrollHeight = 0;
    const { children } = this.pageContent;
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      scrollHeight += child.clientHeight;
    }

    const val = scrollHeight > height || footerShadow;
    this.setState({
      isPageScrollable: val && buttons.length > 0
    });
  }

  render() {
    const { isPageScrollable: footerShadow } = this.state;
    const {
      isPrivacy,
      children,
      className,
      buttons,
      hideLogo,
      isDark,
      isMessage,
      forceFillViewPort,
      onSelectLanguage,
      ariaLive = 'polite',
      containerProps
    } = this.props;

    let backButton = null;
    let actionButtons = buttons;
    if (ENABLE_BACK_BUTTON_ON_HEADER) {
      if (buttons && buttons.length > 0) {
        backButton = buttons.find(
          (item) =>
            item.onClick &&
            ((item.label && item.label.toLowerCase() === 'back') ||
              (item.dataTestId && item.dataTestId.toLowerCase().includes('back')))
        );
        actionButtons = buttons.filter((item) => item !== backButton);
      }
    }

    return (
      <div
        {...containerProps}
        className={classNames(
          classes.wrapper,
          className && wrapperClasses(classes, className),
          isDark && classes.isDark
        )}
      >
        <div
          ref={(pageContent) => {
            this.pageContent = pageContent;
          }}
          className={classes.body}
        >
          {!ENABLE_BACK_BUTTON_ON_HEADER && (
            <Header
              onSelectLanguage={isPrivacy ? onSelectLanguage : undefined}
              isDark={isDark}
              hideLogo={hideLogo || isDark}
            />
          )}
          {backButton && (
            <Button
              variant="link"
              className={classes.backbutton}
              onClick={() => backButton?.onClick?.()}
            >
              <img
                className={classes.imgback}
                alt=""
                src={BACK_BUTTON_ICON_ON_HEADER}
                data-testid="header-button-back-img"
              />
            </Button>
          )}
          <div
            className={classNames(
              classes.content,
              forceFillViewPort && classes['content-force-fill-view-port']
            )}
            aria-live={ariaLive}
          >
            {children}
          </div>
        </div>
        {(!isMessage || (actionButtons && actionButtons.length > 0)) && (
          <div
            className={classNames(
              classes.footer,
              footerShadow && classes.footerShadow,
              isDark && classes.isDark
            )}
            aria-live="polite"
          >
            {actionButtons.length > 0 && (
              <ButtonGroup btnCount={actionButtons.length}>
                {actionButtons.map((button) => (
                  <Button key={button.label} {...button} />
                ))}
              </ButtonGroup>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Page;
