import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './Radio.style.module.scss';

export const Radio = ({ checked, title, id, onChange, className, ...props }) => {
  const iconName = `images/icons/svg/${
    checked ? 'icon_radio_checked.svg' : 'icon_radio_unchecked.svg'
  }`;

  return (
    <label
      {...props}
      className={classNames(classes.option_item, checked && classes.checked, className)}
      htmlFor={id}
    >
      <span className={classes.check_mark_wrapper}>
        <img src={iconName} alt="" />
      </span>
      <input
        id={id}
        name={id}
        className={classes.checkbox}
        type="checkbox"
        onChange={onChange}
        checked={checked}
      />
      <span className={classes.option_title}>{title}</span>
    </label>
  );
};

Radio.propTypes = {
  checked: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string
};
