import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Page from '@lib/components/v2/Page';
import Modal from '@lib/components/v2/Modal';
import { ConfirmModal } from '@lib/components/v2/ConfirmModal';
import { Form } from 'calidation';
import { localizedString } from '@languages';
import { Error500 } from '../../errors';

import APIs from '../../../services/APIs';

import { MoreInfoContent } from '../../components/Contents';

import anzData from '../../../data/anz-fields.json';

class MoreInfo extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * Return the component's initial state
   *
   */
  getInitialState() {
    const state = {
      citizenship: 'Australia',
      occupation: '',
      loading: false,
      fatalError: null,
      errors: {},
      error: null,
      isExitConfirmModalDisplayed: false
    };

    return state;
  }

  /**
   * Handle Custom  Field Changes
   * @return
   */
  handleChange = (id, selectedValue) => {
    const { value } = selectedValue;
    this.setState({ [id]: value });
  };

  handleConfirm = () => {
    this.setState({ loading: true });

    const { citizenship, occupation } = this.state;

    const data = {
      citizenship,
      occupation
    };

    APIs.storeMoreInfo(data)
      .then(({ status, msg }) => {
        this.setState({ loading: false });
        if (status !== 'success') {
          this.setState({
            error: {
              issue: msg,
              buttons: [
                {
                  label: localizedString('cancel'),
                  onClick: () => {
                    this.setState({
                      error: null
                    });
                  }
                }
              ]
            }
          });
        }
        this.props.onNextStep();
      })
      .catch(({ message }) => {
        console.error(message);
        const fatalError = {
          component: Error500,
          props: {
            onTryAgain: () => {
              this.props.on500Error();
            }
          }
        };
        this.setState({ fatalError, loading: false });
      });
  };

  render() {
    const { citizenship, occupation, error, loading, fatalError, isExitConfirmModalDisplayed } =
      this.state;
    const { component: Error, props: errorProps } = fatalError || {};

    const { onGoBack } = this.props;

    const buttons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: () => this.setState({ isExitConfirmModalDisplayed: true })
      },
      {
        label: localizedString('Continue'),
        type: 'submit',
        disabled: !citizenship || !occupation,
        variant: loading ? 'transparent' : 'secandary',
        loading
      }
    ];

    return (
      <div>
        {Error && <Error {...errorProps} />}
        {isExitConfirmModalDisplayed && (
          <ConfirmModal
            heading={localizedString('app.FLOW_V2_EXIT_SCREEN_TITLE')}
            onConfirm={() => onGoBack()}
            onCancel={() => this.setState({ isExitConfirmModalDisplayed: false })}
          >
            {localizedString('app.FLOW_V2_EXIT_SCREEN_DESCRIPTION_DETAILS')}
          </ConfirmModal>
        )}
        {error && <Modal isOpen {...error} />}
        <Form onSubmit={this.handleConfirm}>
          <Page
            title={localizedString('thisAlsoHelpsVerifyYourIdentity')}
            buttons={buttons}
            onGoBack={this.handleGoBack}
          >
            <MoreInfoContent
              optionsDict={anzData}
              onChange={(id, value) => {
                this.handleChange(id, value);
              }}
            />
          </Page>
        </Form>
      </div>
    );
  }
}

MoreInfo.propTypes = {
  onNextStep: PropTypes.func,
  on500Error: PropTypes.func,
  onGoBack: PropTypes.func
};

export default connect(mapStateToProps, null)(MoreInfo);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ appConfig }) {
  return {
    appConfig
  };
}
