import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Input.style.module.scss';

export const Input = ({
  type = 'text',
  paddingLeft30 = false,
  placeholder,
  id,
  className,
  disabled,
  readOnly,
  onChange,
  required,
  value,
  defaultValue,
  hasError,
  dataTestId,
  ...rest
}) => {
  const isTextarea = type === 'textarea';
  const Element = isTextarea ? 'textarea' : 'input';

  const autoComplete =
    ['cardNumber', 'documentNumber'].includes(id) || (placeholder && placeholder.match(/CARD/i))
      ? 'off'
      : 'on';

  return (
    <div
      className={classNames(
        'inputWrapper',
        classes.inputWrapper,
        className,
        paddingLeft30 && classes.leftPadding30Perc,
        hasError && classes.hasError
      )}
    >
      <Element
        {...rest}
        id={id}
        name={id}
        data-testid={dataTestId}
        autoComplete={autoComplete}
        disabled={disabled}
        required={required}
        onChange={handleChange}
        readOnly={readOnly}
        placeholder={placeholder}
        type={isTextarea ? null : type}
        value={value ?? ''}
        defaultValue={defaultValue}
      />
      {hasError && <div className={classes.error}>{hasError}</div>}
    </div>
  );

  function handleChange(e) {
    if (disabled || readOnly || !onChange) {
      return;
    }

    onChange(e.target.value);
  }
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  hasError: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  paddingLeft30: PropTypes.bool,
  type: PropTypes.oneOf([
    'date',
    'datetime-local',
    'email',
    'month',
    'number',
    'password',
    'search',
    'tel',
    'text',
    'textarea',
    'time',
    'url',
    'week',
    'hidden'
  ]),
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  dataTestId: PropTypes.string
};
