import React from 'react';
import PropTypes from 'prop-types';
import Message from '@lib/components/v2/Message';
import { localizedString } from '@languages';
import parse from 'html-react-parser';

export const Error500 = (props) => {
  return (
    <Message {...props} title={localizedString('errors.FLOW_V2_ERROR_500_ALERT_TITLE')} issue>
      {parse(localizedString('errors.FLOW_V2_ERROR_500_ALERT_DESCRIPTION'))}
    </Message>
  );
};

/**
 * Error 400
 */
export const Error400 = (props) => {
  const { onRetryAgain = () => {}, ...restProps } = props;
  return (
    <Message
      {...restProps}
      buttons={[
        {
          label: 'Try Again',
          full: true,
          onClick: onRetryAgain
        }
      ]}
      title="Error - Unstable internet connection"
      issue
    >
      Press "Try Again" to reattempt
    </Message>
  );
};

Error400.propTypes = {
  onRetryAgain: PropTypes.func
};

/**
 * Connectivity issue
 */
export const InternetCut = (props) => {
  return (
    <Message
      {...props}
      buttons={[
        {
          label: 'Back',
          variant: 'transparent',
          onClick: () => document.location.reload()
        },
        {
          label: 'Try Again',
          shadow: true,
          onClick: () => document.location.reload()
        }
      ]}
      title="Connection error"
      issue
    >
      Please check your internet connection and click try again below to reconnect.
    </Message>
  );
};
