import { getCookie } from '@lib/Utils';
import {
  DETAIL_MATCH,
  DETAIL_MATCH_ONLY_UNIVERSAL_URL,
  UNIVERSAL_URL_PATH
} from '@spotMobileConfig';
import { DetailMatch } from '@containers';
import React, { useEffect, useState } from 'react';

export const withDetailMatch = (OriginalComponent) => {
  function WithDetailMatch(props) {
    const [showDetailMatch, setShowDetailMatch] = useState(false);
    useEffect(() => {
      const path = window.location.pathname;
      const detailMatch = getCookie('detailMatch');
      if (
        detailMatch === 'yes' ||
        !DETAIL_MATCH ||
        DETAIL_MATCH.length === 0 ||
        (DETAIL_MATCH_ONLY_UNIVERSAL_URL && path !== UNIVERSAL_URL_PATH)
      ) {
        return;
      }
      setShowDetailMatch(true);
    }, []);

    if (showDetailMatch) {
      return <DetailMatch />;
    }

    return <OriginalComponent {...props} />;
  }

  return WithDetailMatch;
};
