import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Title } from '@lib/components/v2/Title';
import Animation, { useAnimationUrls } from '@components/Animation';
import { localizedString } from '@js/languages';
import { ENABLE_ONE_DOC_CONDITION } from '@spotMobileConfig';
import LoadingBar from '@lib/components/v2/LoadingBar';
import classes from './IdSelection.style.module.scss';

const PORTION_TITLE = 'title';
const PORTION_DESCRIPTION = 'description';

const getContentsForCapture = (documentType, portion) => {
  if (documentType.match(/PASSPORT/i)) {
    if (portion === PORTION_TITLE) {
      return 'Capture your passport';
    }
    if (portion === PORTION_DESCRIPTION) {
      return 'Take a photo of your passport making sure the whole card is visible in the image.';
    }
  } else if (portion === PORTION_TITLE) {
    return 'Capture your I.D';
  } else if (portion === PORTION_DESCRIPTION) {
    return 'Take a photo of your I.D making sure the whole card is visible in the image.';
  }
  return '';
};

const IdSelection = ({ isLoading = false, progressBar, selectedCard = {} }) => {
  const { animationUrls } = useAnimationUrls();

  const animationMap = {
    PASSPORT: animationUrls.PASSPORT,
    AUS_AUTO_BIRTH: animationUrls.CERTIFICATES,
    CITIZENSHIP_CERT: animationUrls.CERTIFICATES,
    AUS_CERT_DESCENT: animationUrls.CERTIFICATES
  };

  let heading = localizedString('idSelection.voiv2.capture.title', selectedCard.title);
  let description = localizedString('idSelection.voiv2.capture.description', selectedCard.title);

  let reviewHeading = localizedString('idSelection.voiv2.loadingDetails.title', selectedCard.title);
  let reviewDescription = localizedString(
    'idSelection.voiv2.loadingDetails.description',
    selectedCard.title
  );

  if (ENABLE_ONE_DOC_CONDITION) {
    if (selectedCard && selectedCard.type) {
      const { type } = selectedCard;
      heading = getContentsForCapture(type, PORTION_TITLE);
      description = getContentsForCapture(type, PORTION_DESCRIPTION);
    }

    reviewHeading = 'Reviewing your ID data';
    reviewDescription =
      'On the next screen, please check carefully that all the data shown matches your card exactly and edit it if needed.';
  }

  return (
    <div style={{ height: '100%' }}>
      {!isLoading && (
        <div className={classes.wrapper}>
          <Title data-testid="docs-heading" title={heading} />
          <div className={classes.description} data-testid="docs-txt">
            {description}
          </div>
          <div className={classes['animation-container']} data-testid="docs-anima">
            <Animation animationUrl={animationMap[selectedCard.type] || animationUrls.CARD} />
          </div>
        </div>
      )}

      {isLoading && (
        <div className={classes.wrapper}>
          <Title data-testid="view-heading" title={reviewHeading} />
          <div className={classnames(classes.description)} data-testid="view-instruction">
            {reviewDescription}
          </div>
          <div className={classes['animation-container']} data-testid="view-anima">
            <Animation animationUrl={animationUrls.REVIEW} />
          </div>
          <LoadingBar width={progressBar} />
        </div>
      )}
    </div>
  );
};

IdSelection.propTypes = {
  isLoading: PropTypes.bool,
  progressBar: PropTypes.number,
  selectedCard: PropTypes.object
};

export default IdSelection;
