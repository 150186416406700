import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Title } from '@lib/components/v2/Title';
import Page from '@lib/components/v2/Page';
import { localizedString } from '@languages';
import classes from './RequiredDocuments.style.module.scss';

class RequiredDocuments extends Component {
  static propTypes = {
    onGoBack: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      requiredDocumentCount: 2
    };

    this.handleGoBack = this.handleGoBack.bind(this);
  }

  handleGoBack(e) {
    if (e) {
      e.preventDefault();
    }
    const { requiredDocumentCount } = this.state;
    const { onGoBack } = this.props;

    onGoBack(requiredDocumentCount);
  }

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const footerButtons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: this.handleGoBack
      },
      {
        label: '',
        variant: 'transparent'
      }
    ];

    return (
      <Page buttons={footerButtons}>
        <div className={classes.wrapper}>
          <Title title="Required Documents" />
          <div className={classes.description}>
            Please make sure you meet one of the minimum document requirement options before you
            begin you VOI.
          </div>
        </div>
      </Page>
    );
  }
}

export default RequiredDocuments;
