export const DOCUMENTS = {
  DIGITAL_DRIVER_LICENCE: {
    documentType: 'DIGITALID',
    cardType: 'Digital Driver Licence'
  },
  DRIVER_LICENCE: {
    documentType: 'Driver Licence',
    cardType: 'Driver Licence'
  },
  PASSPORT: {
    documentType: 'PASSPORT',
    cardType: 'Passport'
  },
  ID_CARD: {
    documentType: 'PROOF_OF_AGE',
    cardType: 'ID Card'
  },
  PHOTO_CARD: {
    documentType: 'PROOF_OF_AGE',
    cardType: 'Photo Card'
  }
};

export const isIdCard = (docType) => {
  return docType === DOCUMENTS.ID_CARD.cardType;
};

export const isProofOfAgeDocument = (docType) => {
  return isIdCard(docType) || docType === DOCUMENTS.PHOTO_CARD.cardType;
};

export const toDocumentType = (cardType) =>
  Object.values(DOCUMENTS).find((doc) => doc.cardType === cardType)?.documentType;

export const ALL_CARD_TYPES = 'ALL';
