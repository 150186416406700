import { localizedString } from '@languages';
import Modal from '@lib/components/v2/Modal';
import React, { useEffect, useState } from 'react';
import Message from '@lib/components/v2/Message';

const TimeoutCheck = (props) => {
  return (
    <Message {...props} title={localizedString('app.timeout.title')} issue>
      {localizedString('app.timeout.content')}
    </Message>
  );
};

export const withTimeoutCheck = (OriginalComponent) => {
  function WithTimeout(props) {
    const [tenMinsLeft, setTenMinsLeft] = useState(false);
    const [hasTimedOut, setHasTimedOut] = useState(false);

    useEffect(() => {
      const countdownInMinutes = 60;

      const timer1 = setTimeout(() => {
        setTenMinsLeft(false);
        setHasTimedOut(true);
      }, countdownInMinutes * 60 * 1000);

      const timer2 = setTimeout(() => {
        setTenMinsLeft(true);
      }, (countdownInMinutes - 10) * 60 * 1000);

      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
      };
    }, []);

    if (hasTimedOut) {
      return <TimeoutCheck />;
    }

    if (tenMinsLeft) {
      return (
        <Modal
          isOpen
          heading={localizedString('tenMinsLeftDesc')}
          buttons={[
            {
              label: localizedString('ok'),
              onClick: () => setTenMinsLeft(false)
            }
          ]}
        />
      );
    }

    return <OriginalComponent {...props} />;
  }

  return WithTimeout;
};
