import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Title } from '@lib/components/v2/Title';
import { getLanguage, LANGUAGES, localizedString, setLanguage } from '@languages';
import Page from '@lib/components/v2/Page';
import classes from './Language.style.module.scss';

const handleAply = (lang) => {
  setLanguage(lang);
};

const Language = ({ onGoback }) => {
  const [lang, setLang] = useState(getLanguage);

  const footerButtons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: onGoback
    },
    {
      label: localizedString('apply'),
      onClick: () => {
        handleAply(lang);
        onGoback();
      }
    }
  ];

  const handleChange = (e) => {
    setLang(e.target.value);
  };

  return (
    <Page buttons={footerButtons}>
      <div className={classes.wrapper}>
        <Title title={localizedString('selectYourLanguage')} />
        <div className={classes.description}>
          <div onChange={handleChange}>
            {LANGUAGES.map(({ name, title, countryCode }) => {
              return (
                <div
                  key={name}
                  className={classnames(
                    'd-flex align-items-center justify-content-between',
                    classes.langRow
                  )}
                >
                  <label className="radio radio-before" htmlFor={`lang-${name}`}>
                    <span className="radio__input">
                      <input
                        id={`lang-${name}`}
                        name={`lang-${name}`}
                        value={name}
                        type="radio"
                        checked={lang === name}
                      />
                      <span className="radio__control" />
                    </span>
                    <span className="radio__label">{title}</span>
                  </label>
                  <span>
                    <img
                      className={classes['img-flag']}
                      src={`assets-portal/images/flags/${countryCode.toLowerCase()}.png`}
                      alt=""
                    />
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Page>
  );
};

Language.propTypes = {
  onGoback: PropTypes.func
};
export default Language;
